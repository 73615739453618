import { answerIsRequired } from "../app/routes/register/personInfo/basicInfo";

export const Error = ({ errors, name, message = "", lang = "en" }) => {
  if (errors[name]) {
    return (
      <p className="text-sm text-red-600" id="employed-error">
        {message ? message : answerIsRequired[lang]}
      </p>
    );
  }
};
