import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { WhyModal } from "../../../../components/alertModal/whyModal";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { updatePerson } from "../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { handleError } from "../../../../utils/handleError";
import {
  errorUnderInput,
  inputLabel,
  inputWrapper,
  questionMain2,
} from "../../../../constants/classnames";
import { answerIsRequired } from "./basicInfo";
import { nextText } from "../../../../components/buttonBackNext";

const whatIsYourRaceText = { en: "what is your race and", es: "¿cuál es su raza y" };
const raceText = { en: "race", es: "raza" };
const nativeText = {
  en: "American Indian or Alaska Native",
  es: "Indígena americano o nativo de Alaska",
};
const asianText = { en: "Asian", es: "Asiático" };
const blackText = { en: "Black", es: "Negro" };
const islanderText = {
  en: "Native Hawaiian or other Pacific Islander",
  es: "Nativo hawaiano u otro isleño del Pacífico",
};
const whiteText = { en: "White", es: "Blanco" };
const otherRaceText = {
  en: "Other (please specify below)",
  es: "Otro (por favor especifica más abajo)",
};

const ethnicityText = { en: "ethnicity", es: "etnicidad" };
const hispanicOrLatino = { en: "Hispanic or Latino", es: "Hispano o Latino" };
const notHispanicOrLatino = { en: "Not Hispanic or Latino", es: "No hispano o latino" };
const iPreferNotToSay = { en: "I prefer not to say", es: "Prefiero no decir" };

const weCareAboutRacialEqualityText = {
  en: "At Rasa, we care about racial and ethnic equality.  This data helps us measure our impact.",
  es: "En Rasa, nos preocupamos por la igualdad racial y étnica. Estos datos nos ayudan a medir nuestro impacto.",
};

export function Ethnicity() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  const [openWhy, setOpenWhy] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const newState = { ...state, ...data };

    const stateToSubmit = {
      profile_id: newState.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: data,
        },
      ],
    };

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in ethn", response);
        // todo: get profileId from response, add it to state before nav
        navigate("/register/created", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in ethn", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };
  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          {/*logo*/}
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-6 flex max-w-3xl flex-col px-4">
          <div className="flex flex-row">
            <h2 className={questionMain2}>
              {whatIsYourRaceText[lang]}{" "}
              <span className="whitespace-nowrap">
                {ethnicityText[lang]}?
                <InformationCircleIcon
                  className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
                  onClick={() => setOpenWhy(true)}
                />
              </span>
            </h2>
          </div>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-10 flex max-w-3xl flex-col px-4">
            {/*  race */}
            <div className="pb-4">
              <label htmlFor="race" className={inputLabel}>
                {raceText[lang]} *
              </label>
              <div className={inputWrapper}>
                <select
                  id="race"
                  name="race"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  onChangeCapture={(e) => {
                    if (e.target.value === "other") {
                      setShowOtherInput(true);
                    } else {
                      setShowOtherInput(false);
                    }
                  }}
                  {...register("race", { required: true })}
                >
                  <option value="" disabled />
                  <option value="american-indian">{nativeText[lang]}</option>
                  <option value="asian">{asianText[lang]}</option>
                  <option value="african-american">{blackText[lang]}</option>
                  <option value="pacific-islander">{islanderText[lang]}</option>
                  <option value="white">{whiteText[lang]}</option>
                  <option value="other">{otherRaceText[lang]}</option>
                  <option value="undisclosed">{iPreferNotToSay[lang]}</option>
                </select>
              </div>
              {errors.race && (
                <div className={errorUnderInput} id="fname-error">
                  {answerIsRequired[lang]}
                </div>
              )}
              {showOtherInput && (
                <div className="py-4">
                  <label htmlFor="race_other" className={inputLabel}>
                    other race *
                  </label>
                  <div className={inputWrapper}>
                    <input
                      type="text"
                      name="race_other"
                      id="race_other"
                      className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                      defaultValue=""
                      aria-invalid="false"
                      aria-describedby="race_other-error"
                      {...register("race_other", { required: true })}
                    />
                  </div>
                  {errors.race_other && (
                    <div className={errorUnderInput} id="race_other-error">
                      {answerIsRequired[lang]}
                    </div>
                  )}
                </div>
              )}
            </div>
            {/*  ethnicity */}
            <div className="pb-4">
              <label htmlFor="ethnicity" className={inputLabel}>
                {ethnicityText[lang]} *
              </label>
              <div className={inputWrapper}>
                <select
                  id="ethnicity"
                  name="ethnicity"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  {...register("ethnicity", { required: true })}
                >
                  <option value="" disabled />
                  <option value="hispanic">{hispanicOrLatino[lang]}</option>
                  <option value="not-hispanic">{notHispanicOrLatino[lang]}</option>
                  <option value="undisclosed">{iPreferNotToSay[lang]}</option>
                </select>
              </div>
              {errors.ethnicity && (
                <div className={errorUnderInput} id="ethnicity-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>
            <WhyModal
              open={openWhy}
              setOpen={setOpenWhy}
              answer={weCareAboutRacialEqualityText[lang]}
              lang={lang}
            />
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
