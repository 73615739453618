import { UT } from "../../../constants/usStates";

const ifYouWouldLIkeToHireText = {
  en: "If you would like to hire Rasa as your lawyer, our fees start at just",
  es: "Si desea contratar a Rasa como su abogado, ¡nuestros honorarios comienzan en solo",
};
const perCaseText = {
  en: "per case!",
  es: "por caso!",
};
export const basedOnText = {
  en: "based on government fee waiver eligibility",
  es: "basado en la elegibilidad para la exención de tarifas del gobierno",
};

export const getPriceSentence = (usState, isFeeWaiverEligible, lang = "en") => {
  if (usState === UT && !isFeeWaiverEligible) {
    return <span className="whitespace-nowrap font-bold">$500 {perCaseText[lang]}</span>;
  } else if (usState === UT) {
    return <span className="whitespace-nowrap font-bold">$250* {perCaseText[lang]}</span>;
  } else {
    return <span className="whitespace-nowrap font-bold">$250 {perCaseText[lang]}</span>;
  }
};

export const PriceBox = ({ usState, isFeeWaiverEligible, isGreen = true, lang = "en" }) => {
  let wrapper = "rounded px-4 py-2 font-semibold";
  let disclaimer = "-mt-2 text-xs text-gray-600";
  if (isGreen) {
    wrapper = "rounded bg-rasa-green-100 px-4 py-2 font-semibold";
    disclaimer = "text-xs text-gray-600";
  }
  return (
    <div className="mx-2 mt-4 text-center text-sm leading-5">
      <div className={wrapper}>
        <div>
          {ifYouWouldLIkeToHireText[lang]} {getPriceSentence(usState, isFeeWaiverEligible, lang)}
        </div>
      </div>
      {usState === UT && isFeeWaiverEligible && (
        <div className={disclaimer}>*{basedOnText[lang]}</div>
      )}
    </div>
  );
};
