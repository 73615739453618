import { Link } from "react-router-dom";

const yesLogin = { en: "yes, take me to login", es: "sí, llévame a iniciar sesión" };
const yesForgot = { en: "yes, but I forgot my password", es: "si, pero olvidé mi contraseña" };
export const LoginForgotButtons = ({ email = "", loginClass, forgotClass, lang = "en" }) => {
  return (
    <>
      <Link to="/login" state={{ email: email }}>
        <button type="button" className={loginClass}>
          <span className="tracking-tighter">{yesLogin[lang]}</span>
        </button>
      </Link>
      <Link to="/forgot" state={{ email: email }}>
        <button type="button" className={forgotClass}>
          <span className="tracking-tighter">{yesForgot[lang]}</span>
        </button>
      </Link>
    </>
  );
};
