import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio } from "../../../../components/radio";
import { Error } from "../../../../components/error";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { useState } from "react";
import { handleError } from "../../../../utils/handleError";
import { container3 } from "../../../../constants/classnames";
import { answerIsRequired } from "../personInfo/basicInfo";
import { noText, yesText } from "../surveyMandatory/openCases";
import { nextText } from "../../../../components/buttonBackNext";

const doYouHaveDisabilityText = {
  en: "do you have a mental or physical disability or a serious health condition?",
  es: "¿tiene una discapacidad física o mental o una condición de salud grave?",
};
const inSomeCircumstancesSubstanceUseText = {
  en: "In some circumstances, substance use disorder qualifies to get assistance from government programs.",
  es: "En algunas circunstancias, el trastorno por uso de sustancias califica para recibir asistencia de programas gubernamentales.",
};
const doesDisabilityJobText = {
  en: "does your disability or condition make it hard for you to get and keep a job?",
  es: "¿su discapacidad o condición le dificulta conseguir y mantener un trabajo?",
};

export function Disability() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ["disability"]: "",
    },
  });
  const current = watch("disability");
  const onSubmit = (data) => {
    if (data.disability === "yes" && !data.disability_no_job) {
      setErrorMessage(answerIsRequired[lang]);
    } else {
      setErrorMessage("");
      const currentMandatorySurvey = state.surveys.find(
        (survey) => survey.type === MANDATORY_SURVEY,
      );
      let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
      if (currentOptionalSurvey) {
        currentOptionalSurvey.answers.disability = data.disability;
        currentOptionalSurvey.answers.disability_no_job = data.disability_no_job;
      } else {
        currentOptionalSurvey = {
          type: OPTIONAL_SURVEY,
          answers: {
            disability: data.disability,
            disability_no_job: data.disability_no_job,
          },
        };
      }

      const stateToSubmit = {
        profile_id: state.profile_id,
        surveys: [currentOptionalSurvey, currentMandatorySurvey],
      };
      adjustState(state, stateToSubmit);
      // eslint-disable-next-line no-console
      console.log("stateToSubmit in disability", stateToSubmit);

      updatePerson(stateToSubmit)
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log("response in disability", response);
          navigate("/register/health-insurance", { state: response });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error in disability", error);
          handleError(error, setGenericErrorMessage, navigate);
        });
    }
  };

  return (
    <>
      <div id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">7. {doYouHaveDisabilityText[lang]} </h2>
          <div className="text-base">({inSomeCircumstancesSubstanceUseText[lang]})</div>
        </div>
      </div>
      <div id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="pb-4">
              <Radio
                name="disability"
                answer="yes"
                label={yesText[lang]}
                register={register}
                {...register("disability", { required: true })}
              />
              <Radio
                name="disability"
                answer="no"
                label={noText[lang]}
                register={register}
                handleMoreChange={() => setErrorMessage("")}
                {...register("disability", { required: true })}
              />
              <Error errors={errors} name="disability" />
            </div>
            {current === "yes" && (
              <div>
                <h2 className="font-serif text-xl">{doesDisabilityJobText[lang]}</h2>
                <div className="pb-4">
                  <Radio
                    name="disability_no_job"
                    answer="yes"
                    label={yesText[lang]}
                    register={register}
                    handleMoreChange={() => setErrorMessage("")}
                    {...register("disability_no_job")}
                  />
                  <Radio
                    name="disability_no_job"
                    answer="no"
                    label={noText[lang]}
                    register={register}
                    handleMoreChange={() => setErrorMessage("")}
                    {...register("disability_no_job")}
                  />
                  {errorMessage && <div className="text-sm text-red-600">{errorMessage}</div>}
                </div>
              </div>
            )}
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2 text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
