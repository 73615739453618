import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { confirmAndContinue, stayAndEdit } from "../basicInfo/ConfirmInfoModal";

const confirmYourOtherNamesText = {
  en: "confirm your other names",
  es: "confirme sus otros nombres",
};
const thisIsTheInfoText = {
  en: "This is the information we will use to look up your records.",
  es: "Esta es la información que usaremos para buscar sus registros.",
};
const pleaseConfirmAgainText = {
  en: "Please confirm your other names are correct:",
  es: "Por favor confirme que sus otros nombres son correctos:",
};

export function ConfirmAliasesModal({ open, setOpen, onSubmit, data, lang = "en" }) {
  const handleContinue = () => {
    onSubmit();
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[75vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Title className="mt-6 px-10 font-serif text-2xl">
              {confirmYourOtherNamesText[lang]}
            </AlertDialog.Title>
            <AlertDialog.Description className="mb-8 px-6 text-lg">
              <div className="container mx-auto mb-16 mt-2 flex max-w-3xl flex-col px-4">
                <div className="pb-4">
                  <label htmlFor="message" className="block leading-6 text-gray-900">
                    {thisIsTheInfoText[lang]}
                  </label>
                  <label className="my-4 block leading-6 text-gray-900">
                    {pleaseConfirmAgainText[lang]}
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    {data.map((alias, index) => {
                      return (
                        <div className="mb-4 font-bold" key={`alias-view-${index}`}>
                          {alias.first_name} {alias.last_name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <button
                    onClick={handleContinue}
                    type="button"
                    className="my-4 w-full rounded-full bg-rasapink p-4 font-semibold text-white hover:shadow-xl"
                  >
                    {confirmAndContinue[lang]}
                  </button>
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="w-full rounded-full bg-white p-4 font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
                  >
                    {stayAndEdit[lang]}
                  </button>
                </div>
              </div>
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
