import RasaLogo from "@images/rasa-logo.svg";
import { Link } from "react-router-dom";
import { logo } from "../../../constants/classnames";

export const overviewText = { en: "question list", es: "lista de preguntas" };
export const logoutText = { en: "log out", es: "finalizar la sesión" };

export const Header = ({ handleOverviewClick, handleLogoutClick }) => {
  const lang = localStorage.getItem("language") || "en";

  return (
    <>
      <div className="flex justify-between">
        <div>
          <Link onClick={handleOverviewClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">
              {overviewText[lang]}
            </div>
          </Link>
        </div>
        <div>
          <Link onClick={handleLogoutClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">
              {logoutText[lang]}
            </div>
          </Link>
        </div>
      </div>
      <div className={logo}>
        <RasaLogo className="h-7" />
      </div>
    </>
  );
};
