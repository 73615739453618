import { useState } from "react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { CaseDetailsModalUT } from "./CaseDetailsModalUT";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { AutoInfoModal } from "./AutoInfoModal";

const expungementEligibleNow = {
  en: "Expungement Eligible Now",
  es: "Eliminación de antecedentes penales elegible ahora",
};
const expungementEligibleLater = {
  en: "Expungement Eligible Later",
  es: "Eliminación elegible más tarde",
};
const autoExpungementEligibleNow = {
  en: "Auto-Expungement Eligible Now",
  es: "Eliminación automática de antecedentes penales es elegible ahora",
};
const autoExpungementEligibleLater = {
  en: "Auto-Expungement Eligible Later",
  es: "Eliminación automática de antecedentes penales es elegible más tarde",
};
const notEligibleForExpungementText = {
  en: "Not eligible for expungement",
  es: "No es elegible para la eliminación de antecedentes penales",
};
const seeRecordText = { en: "see record", es: "ver registro" };

export const CardUT = ({
  data,
  openCases,
  setOpenMessageUs,
  onProbationOrParole,
  receivedAnyMessage,
  hasFreshworks,
  lang,
}) => {
  const [open, setOpen] = useState(false);
  const [openAutoInfo, setOpenAutoInfo] = useState(false);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const dispositionDate = new Date(data.dispositionDate).toLocaleDateString("en-US", options);

  return (
    <div className="my-4 mr-4 rounded bg-white p-4">
      <h4 className="font-serif text-2xl tracking-tight">{data.violation}</h4>
      <h4 className="mb-6 font-serif text-lg tracking-tight">{data.caseNumber}</h4>
      <div className="mb-1 text-gray-500">{dispositionDate}</div>
      <div className="mb-1 text-gray-500">
        {data.severity} - {data.disposition}
      </div>
      {data.expungementEligibleNow ? (
        <>
          {data.expungementEligibleNow && !openCases && !onProbationOrParole && (
            <div>
              <CheckCircleIcon className="float-left mr-1 h-6 text-green-500" />
              <span className="tracking-tighter">{expungementEligibleNow[lang]}</span>
            </div>
          )}
        </>
      ) : (
        <>
          {data.expungementEligibleLater && (
            <div>
              <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
              <span className="tracking-tighter">{expungementEligibleLater[lang]}</span>
            </div>
          )}
          {data.needMoreInfo && (
            <div>
              <QuestionMarkCircleIcon className="float-left mr-1 h-6 text-rasapink" />
              We need more information to help you with this case
            </div>
          )}
        </>
      )}
      {((!data.expungementEligibleNow && !data.expungementEligibleLater && !data.needMoreInfo) ||
        onProbationOrParole ||
        openCases) && (
        <div>
          <XCircleIcon className="float-left mr-1 h-6 text-red-600" />
          <span className="tracking-tighter">{notEligibleForExpungementText[lang]}</span>
        </div>
      )}
      {data.autoExpungementEligibleNow ? (
        <>
          {!openCases && !onProbationOrParole && (
            <div>
              <ExclamationTriangleIcon
                className="float-left mr-1 h-6 text-orange-400"
                onClick={() => setOpenAutoInfo(true)}
              />
              <span className="tracking-tighter">{autoExpungementEligibleNow[lang]}</span>
            </div>
          )}
        </>
      ) : (
        <>
          {data.autoExpungementEligibleLater && (
            <div>
              <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
              <span className="tracking-tighter">{autoExpungementEligibleLater[lang]}</span>
              <InformationCircleIcon
                className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
                onClick={() => setOpenAutoInfo(true)}
              />
            </div>
          )}
        </>
      )}
      <button
        type="button"
        className="my-4 w-full rounded-full border border-rasapink bg-white p-4 font-bold text-black hover:shadow-xl"
        onClick={() => setOpen(true)}
      >
        {seeRecordText[lang]}
      </button>
      <AutoInfoModal open={openAutoInfo} setOpen={setOpenAutoInfo} lang={lang} />
      <CaseDetailsModalUT
        open={open}
        setOpen={setOpen}
        setOpenMessageUs={setOpenMessageUs}
        data={data}
        openCases={openCases}
        onProbationOrParole={onProbationOrParole}
        receivedAnyMessage={receivedAnyMessage}
        autoExpungementEligibleNow={data.autoExpungementEligibleNow}
        hasFreshworks={hasFreshworks}
        lang={lang}
      />
    </div>
  );
};
