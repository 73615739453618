import { basedOnText, getPriceSentence } from "./PriceBox";
import { UT } from "../../../constants/usStates";

const itLooksLikeText = {
  en: "It looks like you may be working with our community partner, ",
  es: "Parece que puedes estar trabajando con nuestro socio comunitario, ",
};
const whileText = { en: "While ", es: "" };
const doesNotProvideText = {
  en: "does NOT provide legal services, they can answer questions if you want to do your own legal paperwork.",
  es: "NO brinda servicios legales, pueden responder preguntas si desea hacer sus propios trámites legales.",
};
const ifYouWouldLikeToHireText = {
  en: "If you would like to hire Rasa as your lawyer, our fees start at ",
  es: "Si desea contratar a Rasa como su abogado, nuestros honorarios comienzan en ",
};

export const CleanSlateBox = ({ isFeeWaiverEligible, lang = "en" }) => {
  return (
    <div className="mb-1 rounded border border-rasalightpurple bg-rasa-purple1-50 p-1 text-center leading-5">
      <div>
        {itLooksLikeText[lang]}
        <b>Clean Slate Utah.</b>
      </div>
      <div className="mt-3">
        {whileText[lang]}
        <span className="text-rasapink underline">
          <a target="_blank" rel="noreferrer" href="https://www.cleanslateutah.org/">
            Clean Slate Utah
          </a>
        </span>{" "}
        {doesNotProvideText[lang]}
      </div>
      <div className="mt-3">
        {ifYouWouldLikeToHireText[lang]}
        {getPriceSentence(UT, isFeeWaiverEligible)}
      </div>
      {isFeeWaiverEligible && <div className="text-xs text-gray-700">*{basedOnText[lang]}</div>}
    </div>
  );
};
