import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio } from "../../../../components/radio";
import { Error } from "../../../../components/error";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { useEffect, useState } from "react";
import { handleError } from "../../../../utils/handleError";
import { container3 } from "../../../../constants/classnames";
import { getQuestionNumber } from "./questionNumbers";
import { noText, yesText } from "../surveyMandatory/openCases";
import { nextText } from "../../../../components/buttonBackNext";

const haveYouBeenDeniedEmploymentText = {
  en: "have you ever been denied employment because of your record?",
  es: "¿alguna vez le han negado el empleo debido a su registro?",
};

export function DeniedEmployment() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [number, setNumber] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    if (state) {
      setNumber(getQuestionNumber("denied_employment", state.utm_code));
    }
  }, [state]);

  const onSubmit = (data) => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      currentOptionalSurvey.answers.denied_employment = data.denied_employment;
    } else {
      currentOptionalSurvey = {
        type: OPTIONAL_SURVEY,
        answers: {
          denied_employment: data.denied_employment,
        },
      };
    }
    const stateToSubmit = {
      profile_id: state.profile_id,
      surveys: [currentOptionalSurvey, currentMandatorySurvey],
    };
    adjustState(state, stateToSubmit);
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in denied_employment", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in denied_employment", response);
        navigate("/register/why-record-clearing", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in denied_employment", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">
            {number} {haveYouBeenDeniedEmploymentText[lang]}
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="pb-4">
              <Radio
                name="denied_employment"
                answer="yes"
                label={yesText[lang]}
                register={register}
                {...register("denied_employment", { required: true })}
              />
              <Radio
                name="denied_employment"
                answer="no"
                label={noText[lang]}
                register={register}
                {...register("denied_employment", { required: true })}
              />
              <Error errors={errors} name="denied_employment" />
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
