import RasaLogo from "@images/rasa-logo.svg";
import { Link } from "react-router-dom";
import { loginText } from "./routes/register/introInfo";

const checkEligibility = { en: "check eligibility", es: "verificar elegibilidad" };

export const App = () => {
  const lang = "en"; // localStorage.getItem("language") ||
  localStorage.setItem("language", lang);
  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          {/*<p className="mb-5 text-center text-3xl font-bold text-gray-700">welcome!</p>*/}
          <Link to={"/register"}>
            <button
              type="button"
              className="mb-4 w-full rounded-full bg-rasapink p-4 font-bold text-white hover:shadow-xl"
            >
              {checkEligibility[lang]}
            </button>
          </Link>
          <Link to="/login">
            <button
              type="button"
              className="my-4 w-full rounded-full bg-white p-4 font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
            >
              {loginText[lang]}
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};
