// 2024
// https://aspe.hhs.gov/sites/default/files/documents/7240229f28375f54435c5b83a3764cd1/detailed-guidelines-2024.pdf
const FEE_WAIVER_LIMIT = "$38,730";
export const additionalAmountMonthly = 672.5;
export const additionalAmount = additionalAmountMonthly * 12;
export const NO_INCOME = "I have no income.";
export const noIncomeText = (lang) => {
  switch (lang) {
    case "es":
      return "No tengo ingresos.";
    default:
      return NO_INCOME;
  }
};
const moreThanText = { en: "more than ", es: "más de " };
const lessThanText = { en: "less than ", es: "menos de " };

const one100 = "$15,060";
const one125 = "$18,825";
export const one150 = "$22,590";
const one200 = "$30,120";
const one250ish = FEE_WAIVER_LIMIT;
export const one300 = "$45,180";
export const incomeRangeOnePersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${one100}`,
    label: lessThanText[lang] + `${one100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${one100} - ${one125}`,
    label: `${one100} - ${one125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${one125} - ${one150}`,
    label: `${one125} - ${one150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${one150} - ${one200}`,
    label: `${one150} - ${one200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${one200} - ${one250ish}`,
    label: `${one200} - ${one250ish}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `${one250ish} - ${one300}`,
    label: `${one250ish} - ${one300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${one300}`,
    label: moreThanText[lang] + `${one300}`,
    info: "over 300% federal poverty 2024",
  },
];

const two100 = "$20,440";
const two125 = "$25,550";
export const two150 = "$30,660";
// 185% 37,814.00
export const two185ish = FEE_WAIVER_LIMIT;
const two200 = "$40,880";
export const two300 = "$61,320";
export const incomeRangeTwoPersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${two100}`,
    label: lessThanText[lang] + `${two100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${two100} - ${two125}`,
    label: `${two100} - ${two125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${two125} - ${two150}`,
    label: `${two125} - ${two150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${two150} - ${two185ish}`,
    label: `${two150} - ${two185ish}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${two185ish} - ${two200}`,
    label: `${two185ish} - ${two200}`,
    info: "185ish%-200% federal poverty 2024",
  },
  {
    value: `${two200} - ${two300}`,
    label: `${two200} - ${two300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${two300}`,
    label: moreThanText[lang] + "$61,320",
    info: "over 300% federal poverty 2024",
  },
];

const three100 = "$25,820";
const three125 = "$32,275";
export const three150 = FEE_WAIVER_LIMIT;
const three200 = "$51,640";
export const three300 = "$77,460";
export const incomeRangeThreePersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${three100}`,
    label: lessThanText[lang] + `${three100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${three100} - ${three125}`,
    label: `${three100} - ${three125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${three125} - ${three150}`,
    label: `${three125} - ${three150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${three150} - ${three200}`,
    label: `${three150} - ${three200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${three200} - ${three300}`,
    label: `${three200} - ${three300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${three300}`,
    label: moreThanText[lang] + `${three300}`,
    info: "over 300% federal poverty 2024",
  },
];

const four100 = "$31,200";
const four125 = "$39,000";
export const four150 = "$46,800";
const four200 = "$62,400";
export const four300 = "$93,600";
export const incomeRangeFourPersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${four100}`,
    label: lessThanText[lang] + `${four100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${four100} - ${four125}`,
    label: `${four100} - ${four125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${four125} - ${four150}`,
    label: `${four125} - ${four150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${four150} - ${four200}`,
    label: `${four150} - ${four200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${four200} - ${four300}`,
    label: `${four200} - ${four300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${four300}`,
    label: moreThanText[lang] + `${four300}`,
    info: "over 300% federal poverty 2024",
  },
];

const five100 = "$36,580";
const five125 = "$45,725";
export const five150 = "$54,870";
const five200 = "$73,160";
export const five300 = "$109,740";
export const incomeRangeFivePersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${five100}`,
    label: lessThanText[lang] + `${five100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${five100} - ${five125}`,
    label: `${five100} - ${five125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${five125} - ${five150}`,
    label: `${five125} - ${five150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${five150} - ${five200}`,
    label: `${five150} - ${five200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${five200} - ${five300}`,
    label: `${five200} - ${five300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${five300}`,
    label: moreThanText[lang] + `${five300}`,
    info: "over 300% federal poverty 2024",
  },
];

const six100 = "$41,960";
const six125 = "$52,450";
export const six150 = "$62,940";
const six200 = "$83,920";
export const six300 = "$125,880";
export const incomeRangeSixPersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${six100}`,
    label: lessThanText[lang] + `${six100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${six100} - ${six125}`,
    label: `${six100} - ${six125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${six125} - ${six150}`,
    label: `${six125} - ${six150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${six150} - ${six200}`,
    label: `${six150} - ${six200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${six200} - ${six300}`,
    label: `${six200} - ${six300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${six300}`,
    label: moreThanText[lang] + `${six300}`,
    info: "over 300% federal poverty 2024",
  },
];

const seven100 = "$47,340";
const seven125 = "$59,175";
export const seven150 = "$71,010";
const seven200 = "$94,680";
export const seven300 = "$142,020";
export const incomeRangeSevenPersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${seven100}`,
    label: lessThanText[lang] + `${seven100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${seven100} - ${seven125}`,
    label: `${seven100} - ${seven125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${seven125} - ${seven150}`,
    label: `${seven125} - ${seven150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${seven150} - ${seven200}`,
    label: `${seven150} - ${seven200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${seven200} - ${seven300}`,
    label: `${seven200} - ${seven300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${seven300}`,
    label: moreThanText[lang] + `${seven300}`,
    info: "over 300% federal poverty 2024",
  },
];

const eight100 = "$52,720";
const eight125 = "$65,900";
export const eight150 = "$79,080";
const eight200 = "$105,440";
export const eight300 = "$158,160";
export const incomeRangeEightPersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${eight100}`,
    label: lessThanText[lang] + `${eight100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${eight100} - ${eight125}`,
    label: `${eight100} - ${eight125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${eight125} - ${eight150}`,
    label: `${eight125} - ${eight150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${eight150} - ${eight200}`,
    label: `${eight150} - ${eight200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${eight200} - ${eight300}`,
    label: `${eight200} - ${eight300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${eight300}`,
    label: moreThanText[lang] + `${eight300}`,
    info: "over 300% federal poverty 2024",
  },
];

const nine100 = "$58,100";
const nine125 = "$72,625";
export const nine150 = "$87,150";
const nine200 = "$116,200";
export const nine300 = "$174,300";
export const incomeRangeNinePersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${nine100}`,
    label: lessThanText[lang] + `${nine100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${nine100} - ${nine125}`,
    label: `${nine100} - ${nine125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${nine125} - ${nine150}`,
    label: `${nine125} - ${nine150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${nine150} - ${nine200}`,
    label: `${nine150} - ${nine200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${nine200} - ${nine300}`,
    label: `${nine200} - ${nine300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${nine300}`,
    label: moreThanText[lang] + `${nine300}`,
    info: "over 300% federal poverty 2024",
  },
];

const ten100 = "$63,480";
const ten125 = "$79,350";
export const ten150 = "$95,220";
const ten200 = "$126,960";
export const ten300 = "$190,440";
export const incomeRangeTenPersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${ten100}`,
    label: lessThanText[lang] + `${ten100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${ten100} - ${ten125}`,
    label: `${ten100} - ${ten125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${ten125} - ${ten150}`,
    label: `${ten125} - ${ten150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${ten150} - ${ten200}`,
    label: `${ten150} - ${ten200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${ten200} - ${ten300}`,
    label: `${ten200} - ${ten300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${ten300}`,
    label: moreThanText[lang] + `${ten300}`,
    info: "over 300% federal poverty 2024",
  },
];

const eleven100 = "$68,860";
const eleven125 = "$86,075";
export const eleven150 = "$103,290";
const eleven200 = "$137,720";
export const eleven300 = "$206,580";
export const incomeRangeElevenPersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${eleven100}`,
    label: lessThanText[lang] + `${eleven100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${eleven100} - ${eleven125}`,
    label: `${eleven100} - ${eleven125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${eleven125} - ${eleven150}`,
    label: `${eleven125} - ${eleven150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${eleven150} - ${eleven200}`,
    label: `${eleven150} - ${eleven200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${eleven200} - ${eleven300}`,
    label: `${eleven200} - ${eleven300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${eleven300}`,
    label: moreThanText[lang] + `${eleven300}`,
    info: "over 300% federal poverty 2024",
  },
];

const twelve100 = "$74,240";
const twelve125 = "$92,800";
export const twelve150 = "$111,360";
const twelve200 = "$148,480";
export const twelve300 = "$222,720";

export const incomeRangeTwelvePersonOptions = (lang) => [
  { value: NO_INCOME, label: noIncomeText(lang) },
  {
    value: `less than ${twelve100}`,
    label: lessThanText[lang] + `${twelve100}`,
    info: "0-100% federal poverty 2024",
  },
  {
    value: `${twelve100} - ${twelve125}`,
    label: `${twelve100} - ${twelve125}`,
    info: "100%-125% federal poverty 2024",
  },
  {
    value: `${twelve125} - ${twelve150}`,
    label: `${twelve125} - ${twelve150}`,
    info: "125%-150% federal poverty 2024",
  },
  {
    value: `${twelve150} - ${twelve200}`,
    label: `${twelve150} - ${twelve200}`,
    info: "150%-200% federal poverty 2024",
  },
  {
    value: `${twelve200} - ${twelve300}`,
    label: `${twelve200} - ${twelve300}`,
    info: "200%-300% federal poverty 2024",
  },
  {
    value: `more than ${twelve300}`,
    label: moreThanText[lang] + `${twelve300}`,
    info: "over 300% federal poverty 2024",
  },
];

const makeInt = (str) => {
  return parseInt(str.replace("$", "").replace(",", ""));
};

export const personTo150 = {
  1: makeInt(one150),
  2: makeInt(two150),
  3: makeInt(three150),
  4: makeInt(four150),
  5: makeInt(five150),
  6: makeInt(six150),
  7: makeInt(seven150),
  8: makeInt(eight150),
  9: makeInt(nine150),
  10: makeInt(ten150),
  11: makeInt(eleven150),
  12: makeInt(twelve150),
};
