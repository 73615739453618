import { useLocation, useNavigate } from "react-router-dom";
import { STATE_LIST } from "../../../constants/usStates";
import { PriceBox } from "../results/PriceBox";
import { useEffect, useState } from "react";
import { MANDATORY_SURVEY } from "../../../constants/surveyTypes";

const thankYouForAnsweringText = {
  en: "thank you for answering our questions!",
  es: "¡gracias por responder nuestras preguntas!",
};
const someRemindersText = { en: "some reminders:", es: "algunos recordatorios:" };
const thisEligibilityToolOnlyText = (usState, lang) => {
  switch (lang) {
    case "es":
      return (
        <>
          Esta herramienta de elegibilidad solo busca registros judiciales de <b>{usState}</b>.
        </>
      );
    default:
      return (
        <>
          This eligibility tool only searches <b>{usState}</b> court records.
        </>
      );
  }
};
const courtDataIsNotPerfectText = {
  en: "Court data is not perfect. Sometimes certain records won't be found, and this could impact your eligibility.",
  es: "Los datos de la corte no son perfectos. A veces, ciertos registros no se encontrarán, y esto podría afectar tu elegibilidad.",
};
const iUnderstandText = { en: "I understand", es: "Entiendo" };

const weDontHaveArrestText = (lang) => {
  switch (lang) {
    case "es":
      return <>No tenemos registros de arresto.</>;
    default:
      return (
        <>
          We <b>don't</b> have <b>arrest</b> records.
        </>
      );
  }
};

export function CourtData() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";

  const [isFeeWaiverEligible, setIsFeeWaiverEligible] = useState(false);

  useEffect(() => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    setIsFeeWaiverEligible(currentMandatorySurvey?.answers?.is_fee_waiver_eligible);
  }, [state.surveys]);

  const handleClick = () => {
    navigate("/register/discount-questions", { state: state });
  };

  return (
    <>
      <section id="message">
        <div className="mt-6 flex justify-center bg-pink-100 py-3 text-sm font-bold">
          <span>{thankYouForAnsweringText[lang]}</span>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className="mb-2 font-sans text-lg font-bold">{someRemindersText[lang]}</h3>
          <div className="overflow-hidden rounded-md bg-pink-100 p-4">
            <ul className="mx-3 list-decimal">
              <li>{thisEligibilityToolOnlyText(STATE_LIST[state.us_state], lang)}</li>
              <li>{weDontHaveArrestText(lang)}</li>
              <li>{courtDataIsNotPerfectText[lang]}</li>
            </ul>
          </div>
          <PriceBox
            usState={state.us_state}
            isFeeWaiverEligible={isFeeWaiverEligible}
            lang={lang}
          />
        </div>
      </section>
      <section id="button">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <button
            type="button"
            onClick={handleClick}
            className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
          >
            {iUnderstandText[lang]}
          </button>
        </div>
      </section>
    </>
  );
}
