import { buttonFlexContainer } from "../constants/classnames";
import { ButtonBack } from "./buttonBack";
import { ButtonNext } from "./buttonNext";

export const nextText = { en: "next", es: "siguiente" };
// const backText = { en: "back", es: "??" };

export const ButtonBackNext = ({
  handleBackClick,
  handleNextClick,
  disabledNext,
  backTitle = "back",
  nextTitle = "next",
}) => {
  return (
    <div className={buttonFlexContainer}>
      <ButtonBack handleClick={handleBackClick} title={backTitle} />
      <ButtonNext handleClick={handleNextClick} disabled={disabledNext} title={nextTitle} />
    </div>
  );
};
