import {
  BCI,
  Complete,
  Court,
  Drafting,
  Final,
  Identification,
  PersonalInfo,
  Prosecutor,
} from "../icons";
import {
  bciApplication,
  caseWithdrawn,
  clientWithdrew,
  draftingFiling,
  expungementComplete,
  expungementDenied,
  finalization,
  finalizationAlt,
  ineligible,
  motionDenied,
  paymentNeededStatus,
  prosecutorObjection,
  prosecutorReview,
  reductionDenied,
  waitingForBci,
  waitingForCourt,
  yourPersonalInfo,
} from "../Descriptions/DescriptionsExpungement";
import { useEffect, useState } from "react";
import { UNKNOWN } from "../../results/results";

export const STATIC = "static";
export const DONE = "done";
export const CURRENT = "current";
export const CURRENT_FAILED = "current failed";
export const DONE_FAILED = "done failed";
export const FUTURE = "future";
export const DEAD_MATTERS = [
  expungementDenied,
  motionDenied,
  reductionDenied,
  ineligible,
  clientWithdrew,
  paymentNeededStatus,
  caseWithdrawn,
  UNKNOWN,
];

export const li = (stepStatus) => {
  let borderColorLight = "border-gray-100";
  let borderColorDark = "border-gray-700";
  if (stepStatus === CURRENT || stepStatus === DONE) {
    borderColorLight = "border-pink-100";
  } else if (stepStatus === DONE_FAILED) {
    borderColorLight = "border-gray-300";
  }
  return `flex w-full items-center after:inline-block after:h-1 after:w-full after:border-4 after:border-b after:${borderColorLight} after:content-[''] dark:after:${borderColorDark}`;
};

export const span = (stepStatus) => {
  let light = "bg-gray-100";
  let dark = "bg-gray-700";
  let size = "h-6 w-6";
  let border = "";
  if (stepStatus === CURRENT) {
    light = "bg-rasapink";
    border = "border border-rasapink ";
    size = "h-10 w-10";
  } else if (stepStatus === DONE) {
    light = "bg-pink-100";
    border = "border border-rasapink ";
  } else if (stepStatus === CURRENT_FAILED) {
    light = "bg-gray-300";
    border = "border border-gray-700 ";
    size = "h-10 w-10";
  } else if (stepStatus === DONE_FAILED) {
    light = "bg-gray-300";
  }
  return `flex ${size} shrink-0 items-center justify-center rounded-full ${border}${light} dark:${dark}`;
};

const stepsExpungement = [
  bciApplication, //0
  yourPersonalInfo, //1
  waitingForBci, //2
  draftingFiling, //3
  prosecutorReview, //4 or prosecutorObjection
  waitingForCourt, //5
  finalization, //6 or finalizationAlt
  expungementComplete, //7
];

const getStepStatus = (currentStep, displayStep) => {
  let currentIndex = stepsExpungement.indexOf(currentStep);
  if (currentStep === prosecutorObjection) {
    currentIndex = 4;
  }
  if (currentStep === finalizationAlt) {
    currentIndex = 6;
  }
  const displayIndex = stepsExpungement.indexOf(displayStep);
  if (displayIndex < currentIndex) {
    return DONE;
  } else if (displayIndex > currentIndex) {
    return FUTURE;
  }
  return CURRENT;
};

export const ProgressStepperExpungement = ({ currentStep }) => {
  const [stepsStatues, setStepsStatues] = useState([
    FUTURE,
    FUTURE,
    FUTURE,
    FUTURE,
    FUTURE,
    FUTURE,
    FUTURE,
    FUTURE,
  ]);

  useEffect(() => {
    const statuses = [];
    for (let i = 0; i < stepsExpungement.length; i++) {
      if (DEAD_MATTERS.includes(currentStep)) {
        statuses[i] = DONE_FAILED;
      } else {
        statuses[i] = getStepStatus(currentStep, stepsExpungement[i]);
      }
    }
    setStepsStatues(statuses);
  }, [currentStep]);

  return (
    <ol className="flex w-full items-center">
      <li className={li(stepsStatues[0])}>
        {/*"flex w-full items-center after:inline-block after:h-1 after:w-full after:border-4 after:border-b after:border-pink-100 after:content-[''] dark:text-rasapink dark:after:border-pink-400"*/}
        <span className={span(stepsStatues[0])}>
          {/*id*/}
          <Identification stepStatus={stepsStatues[0]} />
        </span>
      </li>
      <li className={li(stepsStatues[1])}>
        <span className={span(stepsStatues[1])}>
          <PersonalInfo stepStatus={stepsStatues[1]} />
        </span>
      </li>
      <li className={li(stepsStatues[2])}>
        <span className={span(stepsStatues[2])}>
          {/*building*/}
          <BCI stepStatus={stepsStatues[2]} />
        </span>
      </li>
      <li className={li(stepsStatues[3])}>
        <span className={span(stepsStatues[3])}>
          {/*document*/}
          <Drafting stepStatus={stepsStatues[3]} />
        </span>
      </li>
      <li className={li(stepsStatues[4])}>
        <span className={span(stepsStatues[4])}>
          {/*scale*/}
          <Prosecutor stepStatus={stepsStatues[4]} />
        </span>
      </li>
      <li className={li(stepsStatues[5])}>
        <span className={span(stepsStatues[5])}>
          {/*library building*/}
          <Court stepStatus={stepsStatues[5]} />
        </span>
      </li>
      <li className={li(stepsStatues[6])}>
        <span className={span(stepsStatues[6])}>
          <Final stepStatus={stepsStatues[6]} />
        </span>
      </li>
      <li className="flex items-center">
        <span className={span(stepsStatues[7])}>
          <Complete stepStatus={stepsStatues[7]} />
        </span>
      </li>
    </ol>
  );
};
