import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { UT } from "../../../constants/usStates";

const accountCreatedText = { en: "account created", es: "cuenta creada" };
const thankYouForCreatingText = {
  en: "thank you for creating an account!",
  es: "¡gracias por crear una cuenta!",
};
const nextWeWillAskText = {
  en: "Next, we will ask you a few screening questions to determine the best way to help you.",
  es: "A continuación, te haremos algunas preguntas de evaluación para determinar la mejor manera de ayudarte.",
};
const pleaseAnswerTruthfullyText = {
  en: "Please answer truthfully.",
  es: "Por favor responda con honestidad.",
};
const theAnswersToTheseQuestionsText = {
  en: "The answers to these questions can affect your eligibility for record clearance",
  es: "Las respuestas a estas preguntas pueden afectar su elegibilidad para la autorización de registros",
};
const andQualificationText = {
  en: "and qualification for a government fee waiver.",
  es: "y calificación para una exención de pago del gobierno.",
};
const ifYouQualifyText = {
  en: " If you qualify for a government fee wavier, the government has the right to verify your income",
  es: "Si califica para una exención de pago del gobierno, el gobierno tiene derecho a verificar sus ingresos",
};
export const continueText = { en: "continue", es: "continuar" };

export function AccountCreated() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const usState = localStorage.getItem("us_state");
  const lang = localStorage.getItem("language") || "en";

  const handleClick = () => {
    // todo: profileId will come from backend when account is created
    navigate("/register/open-cases", { state });
  };

  return (
    <>
      {/*logo and nav header*/}
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="success">
        <div className="flex items-center justify-center bg-green-100 py-2 font-bold">
          <span>{accountCreatedText[lang]} </span>
          <CheckCircleIcon className="h-5 text-green-400" />
        </div>
      </section>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">{thankYouForCreatingText[lang]}</h2>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-4 flex max-w-3xl flex-col px-4">
          <div>{nextWeWillAskText[lang]}</div>
          <div className="mt-4">
            <b>{pleaseAnswerTruthfullyText[lang]}</b> {theAnswersToTheseQuestionsText[lang]}
            {usState === UT && (
              <>
                {" "}
                {andQualificationText[lang]} <u>{ifYouQualifyText[lang]}</u>
              </>
            )}
            .
          </div>
        </div>
      </section>
      <section id="button">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <button
            type="button"
            onClick={handleClick}
            className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
          >
            {continueText[lang]}
          </button>
        </div>
      </section>
    </>
  );
}
