import { Link, Outlet, useNavigate } from "react-router-dom";
import { logo } from "../constants/classnames";
import RasaLogo from "@images/rasa-logo.svg";
import { cleanLocalStorage } from "../app/routes/menu";
import { logoutText } from "../app/routes/personalStatementInfo/header";

export function SurveyLayout() {
  const navigate = useNavigate();
  const lang = localStorage.getItem("language") || "en";

  const handleLogoutClick = () => {
    cleanLocalStorage();
    navigate("/login");
  };

  return (
    <>
      <div className="flex justify-between">
        <div />
        <div>
          <Link onClick={handleLogoutClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">
              {logoutText[lang]}
            </div>
          </Link>
        </div>
      </div>
      <div className={logo}>
        <RasaLogo className="h-7" />
      </div>
      <Outlet />
    </>
  );
}
