import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { loading } from "../../../../../components/loading";
import { formatBirthDateFromDashToSlash } from "../../../../../utils/formatBirthdate";
import { primaryButtonSmall2, secondaryButtonSmall } from "../../../../../constants/classnames";
import { firstNameText, lastNameText } from "./basicInfo";

const confirmYourInfo = { en: "confirm your info", es: "confirme su información" };
const thisIsTheInfo = {
  en: "This is the information we will use to look up your records.",
  es: "Esta es la información que usaremos para buscar sus registros.",
};
const pleaseConfirm = {
  en: "Please confirm it is correct:",
  es: "Por favor confirme que es correcto:",
};
const birthDateText = { en: "birth date", es: "fecha de nacimiento" };
export const confirmAndContinue = { en: "confirm and continue", es: "confirmar y continuar" };
export const stayAndEdit = { en: "stay and edit my info", es: "quédate y edita mi info" };

export function ConfirmInfoModal({ open, setOpen, onSubmit, data, isLoading, lang }) {
  const handleContinue = () => {
    onSubmit();
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[80vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Title className="mt-6 px-10 font-serif text-2xl">
              {confirmYourInfo[lang]}
            </AlertDialog.Title>
            <div className="mb-8 px-6 text-lg">
              <div className="container mx-auto mb-16 mt-2 flex max-w-3xl flex-col px-4">
                <div className="pb-4">
                  <label htmlFor="message" className="block leading-6 text-gray-900">
                    {thisIsTheInfo[lang]}
                  </label>
                  <label className="mt-4 block leading-6 text-gray-900">
                    {pleaseConfirm[lang]}
                  </label>
                  <div className="relative mt-2 rounded-md">
                    <div className="px-2">
                      {firstNameText[lang]}: <b>{data.first_name}</b>
                    </div>
                    <div className="px-2">
                      {lastNameText[lang]}: <b>{data.last_name}</b>
                    </div>
                    <div className="px-2">
                      {birthDateText[lang]}:{" "}
                      <b>{formatBirthDateFromDashToSlash(data.birth_date)}</b>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <button onClick={handleContinue} type="button" className={primaryButtonSmall2}>
                    {isLoading ? loading : confirmAndContinue[lang]}
                  </button>
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className={secondaryButtonSmall}
                  >
                    {stayAndEdit[lang]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
