import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { continueAnywayText } from "../register/surveyMandatory/openCases";

const youAreLikelyText = {
  en: "you are likely not eligible right now",
  es: "probablemente no seas elegible en este momento",
};
const openCasesText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <p className="my-6">
          Respondiste que tienes <b>casos abiertos</b>. Todos sus casos deben estar cerrados para
          ser elegible para la autorización de registro.
        </p>
      );
    default:
      return (
        <p className="my-6">
          You answered that you <b>currently have open cases</b>. All your cases must be closed to
          be eligible for record clearance.
        </p>
      );
  }
};
const onProbationText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <p className="my-6">
          Respondió que está en <b>libertad condicional o bajo libertad condicional</b>. Se debe
          completar la libertad condicional o la libertad condicional para ser elegible para la
          autorización de antecedentes penales.
        </p>
      );
    default:
      return (
        <p className="my-6">
          You answered that you are <b>currently on probation or parole</b>. Probation or parole
          must be completed to be eligible for record clearance.
        </p>
      );
  }
};
const iUnderstandText = {
  en: "I understand I'm not currently eligible.",
  es: "Entiendo que actualmente no soy elegible.",
};
export function WarningModal({
  openWarning,
  setOpenWarning,
  openCasesBool,
  probationOrParoleBool,
  lang = "en",
}) {
  return (
    <AlertDialog.Root open={openWarning}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-800 opacity-90" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[110vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex max-h-[80vh] flex-col">
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Title className="my-4 px-6 text-center font-serif text-2xl">
              {youAreLikelyText[lang]}
            </AlertDialog.Title>
            <AlertDialog.Description className="mb-8 px-6 text-xl">
              <div className="my-4 mt-1">
                {openCasesBool && openCasesText(lang)}
                {probationOrParoleBool && onProbationText(lang)}
              </div>
            </AlertDialog.Description>
            <button
              type="button"
              className="m-6 rounded-full bg-rasapink p-4 font-bold text-white hover:shadow-xl"
              onClick={() => setOpenWarning(false)}
            >
              <div>{iUnderstandText[lang]}</div>
              <div>{continueAnywayText[lang]}</div>
            </button>
            <div className="h-4"> .</div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
