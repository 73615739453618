import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertModal, WhyModal } from "@components";
import { useState } from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { updatePerson } from "../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { handleError } from "../../../../utils/handleError";
import {
  errorUnderInput,
  primaryButton,
  questionMain3,
  secondaryButton,
} from "../../../../constants/classnames";
import { handleLogoutClick } from "../../menu";
import { atThisTimeIneligible, continueAnywayText, noText, yesText } from "./openCases";
import { answerIsRequired } from "../personInfo/basicInfo";
import { nextText } from "../../../../components/buttonBackNext";
import { logoutText } from "../personInfo/extendedInfo";

const oweFinesText = {
  en: "do you owe fines, fees, or restitution on any criminal case?",
  es: "¿debe multas, honorarios o restitución en algún caso penal?",
};
const eligibleFinesPaidText = {
  en: "To be eligible for record clearance, your fines/fees/restitution must be paid.",
  es: "Para ser elegible para la eliminación de registros, se deben pagar sus multas/honorarios/restitución.",
};
const iDontKnowText = { en: "I don't know", es: "no sé" };
const whenFinesPaidText = {
  en: "You may have a chance to get your record cleared after your fines/fees/restitution are paid.",
  es: "Después de pagar sus multas/honorarios/restitución, usted podria ser elegible para borrar su registro.",
};

export function OweFines() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";

  const [open, setOpen] = useState(false);
  const [openWhy, setOpenWhy] = useState(false);
  const [localState, setLocalState] = useState(state);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
    const newState = { ...state, ...data };
    setLocalState(newState);
    // eslint-disable-next-line no-console
    console.log("merged state in oweFines", newState);
    if (data.owe_fines === "yes") {
      setOpen(true);
    } else {
      saveAndContinue(newState);
    }
  };

  const continueClick = () => {
    // eslint-disable-next-line no-console
    console.log("data in continue click", localState);
    // eslint-disable-next-line no-console
    console.log("merged state in oweFines continueClick", localState);
    saveAndContinue(localState);
  };

  const saveAndContinue = (currentState) => {
    const currentMandatorySurvey = currentState.surveys.find(
      (survey) => survey.type === MANDATORY_SURVEY,
    );
    // eslint-disable-next-line no-console
    console.log("currentMandatorySurvey", currentMandatorySurvey);
    const stateToSubmit = {
      profile_id: currentState.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: {
            race: currentMandatorySurvey.answers.race,
            race_other: currentMandatorySurvey.answers.race_other || "",
            ethnicity: currentMandatorySurvey.answers.ethnicity,
            open_cases: currentMandatorySurvey.answers.open_cases,
            owe_fines: currentState.owe_fines,
          },
        },
      ],
    };
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in owe fees", stateToSubmit);
    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in oweFines", response);
        navigate("/register/citizen", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in oweFines", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            {oweFinesText[lang]}
            <InformationCircleIcon
              className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
              onClick={() => setOpenWhy(true)}
            />
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-6 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="oweFinesRadio"
                  id="oweFines-yes"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="yes"
                  aria-invalid="false"
                  aria-describedby="owe-fines-error"
                  {...register("owe_fines", { required: true })}
                />
                <label
                  htmlFor="oweFines-yes"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  {yesText[lang]}
                </label>
              </div>
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="oweFinesRadio"
                  id="oweFines-no"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="no"
                  aria-invalid="false"
                  aria-describedby="owe-fines-error"
                  {...register("owe_fines", { required: true })}
                />
                <label
                  htmlFor="oweFines-no"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  {noText[lang]}
                </label>
              </div>
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="oweFinesRadio"
                  id="oweFines-unsure"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="unsure"
                  aria-invalid="false"
                  aria-describedby="owe-fines-error"
                  {...register("owe_fines", { required: true })}
                />
                <label
                  htmlFor="oweFines-unsure"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  {iDontKnowText[lang]}
                </label>
              </div>
              {errors.owe_fines && (
                <div className={errorUnderInput} id="password-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </section>
      <WhyModal
        open={openWhy}
        setOpen={setOpenWhy}
        answer={eligibleFinesPaidText[lang]}
        lang={lang}
      />
      <AlertModal open={open}>
        <div className="mb-4 flex justify-center">
          <ExclamationCircleIcon className="h-20 text-pink-100" />
        </div>
        <AlertDialog.Title className="mb-4 font-serif text-xl">
          {atThisTimeIneligible[lang]}
        </AlertDialog.Title>
        <AlertDialog.Description className="mb-8">
          {whenFinesPaidText[lang]}
        </AlertDialog.Description>
        <button type="button" className={primaryButton} onClick={continueClick}>
          {continueAnywayText[lang]}
        </button>
        <button
          type="button"
          className={secondaryButton}
          onClick={() => handleLogoutClick(navigate)}
        >
          {logoutText[lang]}
        </button>
      </AlertModal>
    </>
  );
}
