const noteText = { en: "Note", es: "Nota" };
const maybeCleanSlateEligText = {
  en: " This case may be eligible for automatic record clearance under Utah's Clean Slate law. Under legislation that went into effect in October 2024, individuals must now fill out a form to be eligible for automatic expungement. That form can be found ",
  es: " Este caso puede ser elegible para la eliminación automática de registros según la ley Clean Slate de Utah. Según la legislación que entró en vigor en octubre de 2024, las personas ahora deben completar un formulario para ser elegibles para la eliminación automática de antecedentes penales. Ese formulario se puede encontrar ",
};
const hereText = { en: "here", es: "aquí" };
const whileItIsFreeText = {
  en: "While it is free of charge to file the form, it is unclear how long it will take the courts or BCI to process an automatic expungement. Under the new law, BCI must prioritize petition-based expungements. ",
  es: "Si bien presentar el formulario es gratuito, no está claro cuánto tiempo les tomará a los tribunales o a la BCI procesar una eliminación automática de antecedentes penales. Según la nueva ley, BCI debe priorizar las eliminaciones de antecedentes penales basadas en peticiones. ",
};
const seeText = { en: "See", es: "Consulte" };
const utahCodeText = { en: "Utah Code", es: "el Código de Utah" };

export const AutoExpungementNote = ({ lang }) => {
  return (
    <>
      <div className="mt-6 rounded-md bg-amber-100 p-2">
        <b>{noteText[lang]}:</b> {maybeCleanSlateEligText[lang]}
        <span className="text-rasapink underline">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.utcourts.gov/en/self-help/case-categories/criminal-justice/expunge.html#accordion-e952617792-item-2d3c0c94ad"
          >
            {hereText[lang]}
          </a>
        </span>
        . {whileItIsFreeText[lang]}
        <span className="text-rasapink underline">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://le.utah.gov/xcode/Title77/Chapter40A/77-40a-S401.html"
          >
            <i>{seeText[lang]}</i> {utahCodeText[lang]} 77-40a-401(1).
          </a>
        </span>
      </div>
    </>
  );
};
