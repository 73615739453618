import { isMatch, isValid, parse } from "date-fns";
import { enGB } from "date-fns/locale";
import {
  bciApplication,
  clientWithdrew,
  draftingFiling,
  expungementComplete,
  finalization,
  expungementDenied,
  prosecutorReview,
  reductionComplete,
  waitingForBci,
  waitingForCourt,
  yourPersonalInfo,
  paymentNeededStatus,
  prosecutorObjection,
  ineligibleMispelled,
  ineligible,
  reductionDenied,
  waitingForOtherWork,
  motionComplete,
  caseWithdrawn,
  criminalHistoryReportRecommended,
  finalizationAlt,
} from "./Descriptions/DescriptionsExpungement";

export const formatDashesString = "yyyy-MM-dd";
export const formatLongString = "MM/dd/yyyy";
export const formatShortString = "M/d/yyyy";
export const isDateValid = (date) => {
  const parsedDate = parse(date, formatLongString, new Date(), { locale: enGB });
  const splitDate = date.split("/");
  return (
    isValid(parsedDate) &&
    splitDate.length === 3 &&
    splitDate[2].length > 1 &&
    parseInt(splitDate[2]) >= 2023 &&
    (isMatch(date, formatLongString) || isMatch(date, formatShortString))
  );
};

const setYearFromCaseNumber = (m) => {
  let year = null;
  const firstTwoChars = m.case_number.substring(0, 2);
  const possibleInt = parseInt(firstTwoChars[0]);
  if (!isNaN(possibleInt)) {
    if (possibleInt < 5) {
      year = "20" + firstTwoChars;
    } else {
      year = "19" + firstTwoChars;
    }
  }
  m.case_year = year;
};

const ExpungementBCIPhase = "expungement - bci phase"; // "Expungement - BCI Phase";
export const bciAppPhase = "no bci submission date";
const waitingForOther = ["apply after 402", "will reapply later"];
export const needPS = "need ps"; // "NEED PS";

const ExpungementCourtPhase = "expungement - court phase";
const courtPhase = [
  "file request to submit",
  "file rts",
  "waiting for final order",
  "need copy order",
  "still showing in xchange- order sent to bci",
  "still showing in xchange- sent order to bci",
  "order received- need final bci",
  "order received, sent order",
];
const draftingPhase = [
  "ready to draft",
  "purchased certs",
  "purchased certificates",
  "drafted",
  "waiting on bci #",
  "ready to file",
  "ready to file- waiting on last payment",
  "waiting on last payment",
  // "NEED FEE WAIVER",
  "need fee waiver",
];
export const ObjectionExpungement = "objection expungement";
export const ObjectionReceived = "objection received";
const ExpungementFinalizationDisengagement = "expungement - finalization & disengagement";
const expungementFinalizationPhase = [
  "need final bci- sent order",
  "need final bci-sent order twice",
  "need final bci- sent order twice",
  "need final bci- letter sent to client or cs",
  "need final bci- sent to client",
  // "payment owing",
];

const completePhase = [
  "auto expunged (post-filing)",
  "auto expunged post filing",
  "auto expunged (post filing)",
  "auto expunged pre filing",
  "auto expunged (pre-filing)",
  "auto expunged (pre filing)",
  "completed",
  "collect client review",
  "waiting for all matters",
  "audit",
  "collect client review",
  "disengagement letter",
  "disengagement letter/send docs",
];

const clientEnded = [
  // "CLIENT DISENGAGED",
  "client disengaged",
  // "Client Withdrew",
  "client withdrew",
  // "CLIENT WITHDREW INELIGIBLE",
  "client withdrew ineligible",
  "client withdrew /ineligible",
  // "DELETED_FROM_CLIO",
  "deleted_from_clio",
  "deleted from clio",
];
const INVOICE = "invoice";
const OWES = "owes";
const PAYMENT = "payment";
const PMT = "pmt";
const UNKNOWN = "unknown";

export const mapExpungementWorkflowToStatus = (
  m,
  setPaymentNeeded,
  showPsNeeded,
  showBciDateNeeded,
) => {
  setYearFromCaseNumber(m);

  const workflow = m.workflow?.trim().toLowerCase(); // this is called "practice area" in clio
  const workflow_stage = m.workflow_stage?.trim().toLowerCase();
  if (workflow_stage === undefined || workflow_stage === null || workflow_stage === "") {
    m.status = UNKNOWN;
  } else if (workflow_stage === expungementDenied) {
    m.status = expungementDenied;
  } else if (clientEnded.includes(workflow_stage)) {
    m.status = clientWithdrew;
  } else if (workflow_stage === ineligible || workflow_stage === ineligibleMispelled) {
    m.status = ineligible;
  } else if (
    workflow !== ObjectionReceived &&
    (workflow_stage?.includes(INVOICE) ||
      workflow_stage?.includes(OWES) ||
      workflow_stage?.includes(PAYMENT))
  ) {
    m.status = paymentNeededStatus;
    setPaymentNeeded(true);
  } else if (workflow === ExpungementBCIPhase) {
    if (workflow_stage.includes(bciAppPhase)) {
      if (showBciDateNeeded) {
        m.status = bciApplication;
      } else if (showPsNeeded) {
        m.status = yourPersonalInfo;
      } else {
        m.status = waitingForBci;
      }
    } else if (waitingForOther.includes(workflow_stage)) {
      m.status = waitingForOtherWork;
    } else if (workflow_stage === needPS && showPsNeeded) {
      m.status = yourPersonalInfo;
    } else {
      m.status = waitingForBci;
    }
  } else if (workflow === ExpungementCourtPhase) {
    if (courtPhase.includes(workflow_stage)) {
      m.status = waitingForCourt;
    } else if (draftingPhase.includes(workflow_stage)) {
      m.status = draftingFiling;
    } else if (expungementFinalizationPhase.includes(workflow_stage)) {
      m.status = finalization;
    } else {
      m.status = prosecutorReview;
    }
  } else if (workflow === ObjectionReceived || workflow === ObjectionExpungement) {
    if (workflow_stage === "withdrawal as counsel" || workflow_stage === "withdraw as counsel") {
      m.status = caseWithdrawn;
    } else {
      m.status = prosecutorObjection;
    }
  } else if (workflow === ExpungementFinalizationDisengagement) {
    if (completePhase.includes(workflow_stage)) {
      m.status = expungementComplete;
    } else if (expungementFinalizationPhase.includes(workflow_stage)) {
      m.status = finalization;
    } else if (criminalHistoryReportRecommended) {
      m.status = finalizationAlt;
    }
  }
  return m;
};

const needInfoFromClient = "need info from client";

const reductionAndOtherDrafting = [
  "ready to draft",
  "ready to review",
  "ready to file",
  "motion for refund ready to draft",
];
// const reductionProsec = [
//   "waiting for prosecutor",
//   "objection received",
//   "response filed",
// ];
const reductionAndOtherCourt = [
  "file request to submit",
  "file rts",
  "waiting for final order",
  "waiting for order",
  "reduction not showing on docket",
  "hearing scheduled",
];
// const reductionFinal = [
//   "Granted-Review for Expunge Eligibility",
//   "GRANTED -REVIEW FOR ELIG",
//   "WITHDRAW AS COUNSEL",
//   "Motion WD Counsel Filed, Wait on Order Withdraw",
// ];

export const mapReductionWorkflowToStatus = (m, setPaymentNeeded, showPsNeeded) => {
  setYearFromCaseNumber(m);

  const workflow_stage = m.workflow_stage?.trim().toLowerCase();
  if (workflow_stage === undefined || workflow_stage === null || workflow_stage === "") {
    m.status = UNKNOWN;
  } else if (
    !workflow_stage?.includes("objection") &&
    (workflow_stage?.includes(INVOICE) ||
      workflow_stage?.includes(OWES) ||
      workflow_stage?.includes(PMT) ||
      workflow_stage?.includes(PAYMENT))
  ) {
    m.status = paymentNeededStatus;
    setPaymentNeeded(true);
  } else if (workflow_stage?.includes("objection")) {
    if (m.is_two_step) {
      m.status = reductionDenied;
    } else {
      m.status = prosecutorObjection;
    }
  } else if (workflow_stage.includes(needInfoFromClient)) {
    if (showPsNeeded) {
      m.status = yourPersonalInfo;
    } else {
      m.status = draftingFiling;
    }
  } else if (reductionAndOtherDrafting.includes(workflow_stage)) {
    m.status = draftingFiling;
  } else if (reductionAndOtherCourt.includes(workflow_stage)) {
    m.status = waitingForCourt;
  } else if (workflow_stage?.includes("complete") || workflow_stage?.includes("granted")) {
    m.status = reductionComplete;
  } else if (
    clientEnded.includes(workflow_stage) ||
    workflow_stage.includes("withdraw") ||
    workflow_stage.includes("withdrew")
  ) {
    m.status = clientWithdrew;
  } else {
    m.status = prosecutorReview;
  }
  return m;
};

const MotionWithdrawnFromConsideration = "Motion withdrawn from consideration";
export const mapOtherMotionWorkflowToStatus = (m) => {
  setYearFromCaseNumber(m);

  const workflow_stage = m.workflow_stage?.trim().toLowerCase();
  if (workflow_stage === undefined || workflow_stage === null || workflow_stage === "") {
    m.status = UNKNOWN;
  } else if (
    workflow_stage?.includes("denied") ||
    workflow_stage?.includes(MotionWithdrawnFromConsideration)
  ) {
    m.status = caseWithdrawn;
  } else if (reductionAndOtherDrafting.includes(workflow_stage)) {
    m.status = draftingFiling;
  } else if (reductionAndOtherCourt.includes(workflow_stage)) {
    m.status = waitingForCourt;
    // } else if (workflow_stage?.includes("granted")) {
    //   m.status = finalization;
  } else if (workflow_stage?.includes("granted") || workflow_stage?.includes("complete")) {
    m.status = motionComplete;
  } else if (clientEnded.includes(workflow_stage)) {
    m.status = clientWithdrew;
  } else {
    m.status = prosecutorReview;
  }
  return m;
};
