import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { labelMain, tertiaryButton } from "../../../constants/classnames";
import { AZ, UT } from "../../../constants/usStates";
import { scm_utm, REFERRAL_CLIENT } from "../../../constants/utmCodes";
import { useEffect, useState } from "react";
import { LoginForgotButtons } from "./personInfo/basicInfo/loginForgotButtons";

const selectYourState = { en: "select your state", es: "seleccione su estado" };
const otherState = { en: "other state", es: "otro estado" };
const alreadyCreated = { en: "already created an account?", es: "¿ya creó una cuenta?" };

export function Intro() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [lang, setLang] = useState("en");

  useEffect(() => {
    const utmCode = searchParams.get("utm_campaign");
    const language = "en"; //searchParams.get("lang"); todo
    setLang(language);
    localStorage.setItem("language", language);
    if (utmCode && utmCode === scm_utm) {
      localStorage.setItem("utm_code", utmCode);
    }
  }, [searchParams]);

  const handleClick = (usState) => {
    const utmCode = searchParams.get("utm_campaign");
    const newState = {
      utm_code: utmCode,
      us_state: usState,
    };
    const pathname = location.pathname;
    let peer_referrer_code = "";
    if (pathname.startsWith("/r/")) {
      peer_referrer_code = pathname.replace("/r/", "").trim();
      newState.peer_referrer_code = peer_referrer_code;
      newState.utm_code = REFERRAL_CLIENT;
    }
    navigate("/register/intro-info", { state: newState });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="button">
        <div className="container mx-auto mb-16 mt-10 flex flex-col items-center px-4">
          <h2 className={labelMain}>{selectYourState[lang]}</h2>
          <button
            onClick={() => handleClick(AZ)}
            type="button"
            className="mb-4 min-w-60 rounded-full bg-rasapink p-4 font-bold text-white hover:shadow-xl"
          >
            arizona
          </button>
          <button
            onClick={() => handleClick(UT)}
            type="button"
            className="mb-4 min-w-60 rounded-full bg-rasapink p-4 font-bold text-white hover:shadow-xl"
          >
            utah
          </button>
          <div className="mb-10 font-semibold text-rasapink underline">
            <a target="_blank" rel="noreferrer" href="https://forms.gle/VPwqYRNwcSvtgFz98">
              {otherState[lang]}
            </a>
          </div>
          <div className="mb-2 font-semibold italic">{alreadyCreated[lang]}</div>
          <LoginForgotButtons
            loginClass="mb-4 min-w-60 rounded-full bg-white p-4 font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
            forgotClass={tertiaryButton}
            lang={lang}
          />
        </div>
      </section>
    </>
  );
}
