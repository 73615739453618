import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { handleError } from "../../../../utils/handleError";
import { container3 } from "../../../../constants/classnames";
import { Radio } from "../../../../components/radio";
import { Error } from "../../../../components/error";
import { getQuestionNumber } from "./questionNumbers";
import { pleaseSpecifyHereText } from "./industryCurrent";
import { nextText } from "../../../../components/buttonBackNext";

const whatIsYourReasonClearingText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <>
          ¿cuál es su razón <u>número uno</u> para querer limpiar tu registro?
        </>
      );
    default:
      return (
        <>
          what is your <u>number one</u> reason for wanting to clear your record?
        </>
      );
  }
};

const volunteerChildSchoolText = {
  en: "to be able to volunteer at my child's school",
  es: "para poder ser voluntario en la escuela de mi hijo",
};
const stigmaText = {
  en: "to eliminate the stigma associated with a record",
  es: "para eliminar el estigma asociado con un registro",
};
const collegeEducationText = {
  en: "to get a college education",
  es: "para obtener una educación universitaria",
};
const jobText = { en: "to get a new job", es: "para conseguir un nuevo trabajo" };
const professionalLicenseText = {
  en: "to get a professional license",
  es: "para obtener una licencia profesional",
};
const promotionText = { en: "to get a promotion", es: "para conseguir una promoción" };
const rentText = {
  en: "to improve ability to rent a house or apartment",
  es: "para mejorar la capacidad de alquilar una casa o apartamento",
};
const selfConfidenceText = {
  en: "to increase self-confidence",
  es: "para aumentar la confianza en uno mismo",
};
const lifeInsuranceText = { en: "to obtain life insurance", es: "para obtener un seguro de vida" };
const gunRightsText = {
  en: "to restore my gun rights",
  es: "para restaurar mis derechos de posesión de armas",
};
const travelFreelyText = { en: "to travel freely", es: "para viajar libremente" };
const voteText = { en: "to vote", es: "para votar" };
const religiousReasonsText = { en: "for religious reasons", es: "por razones religiosas" };
export const otherLowercaseText = {
  en: "other (please specify below)",
  es: "otro (por favor especifica más abajo)",
};

export function WhyRecordClearing() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [number, setNumber] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    if (state) {
      setNumber(getQuestionNumber("clearing_reason", state.utm_code));
    }
  }, [state]);

  const onSubmit = (data) => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      currentOptionalSurvey.answers.clearing_reason = data.clearing_reason;
      currentOptionalSurvey.answers.clearing_reason_other = data.clearing_reason_other;
    } else {
      currentOptionalSurvey = {
        type: OPTIONAL_SURVEY,
        answers: {
          clearing_reason: data.clearing_reason,
          clearing_reason_other: data.clearing_reason_other,
        },
      };
    }
    const stateToSubmit = {
      profile_id: state.profile_id,
      surveys: [currentOptionalSurvey, currentMandatorySurvey],
    };
    adjustState(state, stateToSubmit);
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in clearing_reason", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in clearing_reason", response);
        navigate("/register/interested-info", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in clearing_reason", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">
            {number} {whatIsYourReasonClearingText(lang)}
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="relative mb-4 mt-2 rounded-md shadow-sm">
              <Radio
                name="clearing_reason"
                answer="to be able to volunteer at my children's school"
                label={volunteerChildSchoolText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to eliminate the stigma associated with a record"
                label={stigmaText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to get a college education"
                label={collegeEducationText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to get a new job"
                label={jobText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to get a professional license"
                label={professionalLicenseText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to get a promotion"
                label={promotionText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to improve ability to rent a house or apartment"
                label={rentText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to increase self-confidence"
                label={selfConfidenceText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to obtain life insurance"
                label={lifeInsuranceText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to restore my gun rights"
                label={gunRightsText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to travel freely"
                label={travelFreelyText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="to vote"
                label={voteText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="for religious reasons"
                label={religiousReasonsText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Radio
                name="clearing_reason"
                answer="other (please specify below)"
                label={otherLowercaseText[lang]}
                register={register}
                {...register("clearing_reason", { required: true })}
              />
              <Error errors={errors} name="clearing_reason" />
            </div>
            <div className="relative mb-4 mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="clearing_reason_other"
                id="clearing_reason_other"
                className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
              placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                placeholder={pleaseSpecifyHereText[lang]}
                defaultValue=""
                aria-invalid="false"
                aria-describedby="clearing_reason_other-error"
                {...register("clearing_reason_other")}
              />
              {/*<Error errors={errors} name="clearing_reason_other" />*/}
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-6 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
