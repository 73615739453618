import { useLocation, useNavigate } from "react-router-dom";
import { primaryButton, secondaryButton } from "../../../../constants/classnames";
import { useEffect, useState } from "react";
import { isFreeUtm } from "../../../../constants/utmCodes";
import { continueText } from "../accountCreated";

const thePartnershipText = {
  en: "The partnership or promotion that is covering the cost of this eligibility screening requires the following demographic questions to be answered.",
  es: "La asociación que cubre el costo de esta evaluación de elegibilidad requiere que se respondan las siguientes preguntas demográficas.",
};
const willYouAnswerFreeText = {
  en: "will you answer 12 short questions to see your results for free?",
  es: "¿responderá 12 preguntas cortas para ver sus resultados gratis?",
};
const willYouAnswerDiscountText = {
  en: "will you answer 12 short questions for a $10 discount?",
  es: "¿responderá 12 preguntas cortas para un descuento de $10?",
};
const thisScreeningUsuallyCostsText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <>
          <div className="mb-6 rounded-md bg-pink-100 px-6 py-4 text-center">
            ¡Esta evaluación generalmente cuesta $15, pero usted obtendrá $5 de descuento porque
            utilizó el enlace de referencia de su amigo!
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="mb-6 rounded-md bg-pink-100 px-6 py-4 text-center">
            <div>This screening usually costs $15,</div>
            <div>but you are getting $5 off because you used your friend's referral link!</div>
          </div>
        </>
      );
  }
};
const weAreContinuallyText = {
  en: "We are continually trying to build partnerships to lower the cost of record clearance services. Gathering data about how a record can impact a person's housing, employment, and education can help us make the case for these partnerships and clear more records.",
  es: "Estamos tratando de construir asociaciones continuamente para reducir el costo de los servicios de eliminación de registros. Recopilar información sobre cómo los antecedentes penales pueden afectar la vivienda, el empleo y la educación de una persona puede ayudarnos a allanar el camino para más asociaciones.",
};
const inAdditionWeWantToText = {
  en: "In addition, we want to make you aware of potential fair chance hiring opportunities, scholarships, and other benefits that could help a person with a record improve their life.",
  es: "Además, queremos informarle sobre posibles oportunidades de empleo con igualdad de oportunidades, becas y otros beneficios que podrían ayudar a una persona con un registro a mejorar su vida.",
};
const ifYouAreWillingText = {
  en: "If you are willing to help us, we will provide a $10 discount on our eligibility tool.",
  es: "Si estás dispuesto a ayudarnos, podemos ofrecerle un descuento de $10 para utilizar la herramienta de elegibilidad.",
};
const continueForFree = { en: "continue for free", es: "continuar gratis" };
const get10Discount = { en: "get $10 discount", es: "obtenga $10 de descuento" };
const goToPaymentText = (amount, lang) => {
  switch (lang) {
    case "es":
      return `ir al pago de $${amount}`;
    default:
      return `go to $${amount} payment`;
  }
};

export function DiscountQuestions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  // eslint-disable-next-line no-console
  console.log("state in discount questions", state);
  const [isFree, setIsFree] = useState(false);
  const [hasPeerReferrer, setHasPeerReferrer] = useState(false);
  const [amount, setAmount] = useState("15");

  useEffect(() => {
    const utm_code = state?.utm_code;
    if (isFreeUtm(utm_code)) {
      setIsFree(true);
    } else {
      setIsFree(false);
    }
    if (state?.has_peer_referrer) {
      setHasPeerReferrer(true);
      setAmount("10");
    }
  }, [state]);

  const handleToDiscountQuestionsClick = () => {
    navigate("/register/employed", { state });
  };

  const handleToPaymentClick = () => {
    navigate("/register/records-ready", { state: { ...state, amount: amount } });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          {hasPeerReferrer && (
            <div>
              {thisScreeningUsuallyCostsText(lang)}
              <h3 className="font-sans text-xl font-bold">{willYouAnswerFreeText[lang]}</h3>
            </div>
          )}
          {isFree && <h3 className="font-sans text-xl font-bold">{thePartnershipText[lang]}</h3>}
          {!isFree && !hasPeerReferrer && (
            <h3 className="font-sans text-xl font-bold">{willYouAnswerDiscountText[lang]}</h3>
          )}
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <div className="mb-4">{weAreContinuallyText[lang]}</div>
          <div className="mb-6">{inAdditionWeWantToText[lang]}</div>
          {!isFree && <div className="mb-6">{ifYouAreWillingText[lang]}</div>}
        </div>
      </section>
      <section id="button" className="mb-6">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <div className="mb-6 overflow-hidden rounded-md border-2 border-gray-200 px-6 py-4">
            {isFree ? (
              <button
                type="button"
                onClick={handleToDiscountQuestionsClick}
                className={primaryButton}
              >
                {continueText[lang]}
              </button>
            ) : (
              <>
                <button
                  type="button"
                  onClick={handleToDiscountQuestionsClick}
                  className={primaryButton}
                >
                  {hasPeerReferrer ? continueForFree[lang] : get10Discount[lang]}
                </button>
                <div className="mt-2">
                  <button type="button" onClick={handleToPaymentClick} className={secondaryButton}>
                    {goToPaymentText(amount, lang)}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
