import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { nextText } from "../../../../../components/buttonBackNext";

const pleaseGiveUsMorInfo = {
  en: "Please give us more information about your out-of-state or federal cases so we can determine the best way to help you.",
  es: "Por favor, proporciónanos más información sobre sus casos fuera del estado o federales para que podamos determinar la mejor manera de ayudarlo.",
};

export function Step1({ next }) {
  const lang = localStorage.getItem("language") || "en";

  return (
    <div className="p-6">
      <AlertDialog.Description className="mb-8">
        {pleaseGiveUsMorInfo[lang]}
      </AlertDialog.Description>
      <button
        type="button"
        className="w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
        onClick={next}
      >
        {nextText[lang]}
      </button>
    </div>
  );
}
