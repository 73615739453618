import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio } from "../../../../components/radio";
import { Error } from "../../../../components/error";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { handleError } from "../../../../utils/handleError";
import { useState } from "react";
import { container3, input, inputLabel, inputWrapper } from "../../../../constants/classnames";
import { noText } from "../surveyMandatory/openCases";
import { nextText } from "../../../../components/buttonBackNext";

const areYouCurrentlyEmployedText = {
  en: "are you currently employed?",
  es: "¿está empleado?",
};
const yesFullTimeText = { en: "yes, full time", es: "sí, a tiempo completo" };
const yes21_32Text = {
  en: "yes, part time. 21 - 32 hrs / week",
  es: "sí, a tiempo parcial. 21 - 32 hrs / semana",
};
const yes11_20Text = {
  en: "yes, part time. 11 - 20 hrs / week",
  es: "sí, a tiempo parcial. 11 - 20 hrs / semana",
};
const yes1_10Text = {
  en: "yes, part time. 1 - 10 hrs / week",
  es: "sí, a tiempo parcial. 1 - 10 hrs / semana",
};
const ifYesWhereText = {
  en: "If yes, where do you work?",
  es: "Si respondiste que sí, ¿dónde trabaja?",
};

export function Employed() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const newState = { ...state, ...data };
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      currentOptionalSurvey.answers.employed = data.employed;
    } else {
      currentOptionalSurvey = {
        type: OPTIONAL_SURVEY,
        answers: {
          employed: data.employed,
          employer: data.employer,
        },
      };
    }
    const stateToSubmit = {
      profile_id: newState.profile_id,
      surveys: [currentOptionalSurvey, currentMandatorySurvey],
    };
    adjustState(state, stateToSubmit);
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in employed", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in employed", response);
        if (data.employed.startsWith("yes")) {
          navigate("/register/pay-rate", { state: response });
        } else {
          navigate("/register/industry-want", { state: response });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in ethn", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">1. {areYouCurrentlyEmployedText[lang]}</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="pb-4">
              <Radio
                name="employed"
                answer="yes-fulltime"
                label={yesFullTimeText[lang]}
                register={register}
                {...register("employed", { required: true })}
              />
              <Radio
                name="employed"
                answer="yes-parttime-21-32"
                label={yes21_32Text[lang]}
                register={register}
                {...register("employed", { required: true })}
              />
              <Radio
                name="employed"
                answer="yes-parttime-11-20"
                label={yes11_20Text[lang]}
                register={register}
                {...register("employed", { required: true })}
              />
              <Radio
                name="employed"
                answer="yes-parttime-1-10"
                label={yes1_10Text[lang]}
                register={register}
                {...register("employed", { required: true })}
              />
              <Radio
                name="employed"
                answer="no"
                label={noText[lang]}
                register={register}
                {...register("employed", { required: true })}
              />
              <Error errors={errors} name="employed" lang={lang} />
            </div>
            <div className="pb-4">
              <label htmlFor="password" className={inputLabel}>
                {ifYesWhereText[lang]}
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="employer"
                  id="employer"
                  className={input}
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="employer-error"
                  {...register("employer")}
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
