import { AlertModal } from "@components";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useForm } from "react-hook-form";
import { errorUnderInput } from "../../../../../constants/classnames";
import { ifYouveClearedBeforeText } from "./expunged";
import { answerIsRequired } from "../../personInfo/basicInfo";
import { nextText } from "../../../../../components/buttonBackNext";

const pleaseProvideMoreInfoText = {
  en: "Please provide us with more information about the records you have already cleared:",
  es: "Proporciónanos más información sobre los registros que ya has limpiado:",
};
const tellUsYourStoryText = { en: "tell us your story", es: "cuéntanos tu historia" };

export function ExpungedModal({ open, setOpen, done, lang }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const onSubmit = (data) => {
    setOpen(false);
    done(data);
  };
  return (
    <AlertModal open={open} className="p-0">
      <div className="flex h-[95vh] flex-col py-6">
        <AlertDialog.Title className="mb-4 px-6 font-serif text-xl">
          {ifYouveClearedBeforeText[lang]}
        </AlertDialog.Title>
        <AlertDialog.Description className="mb-8 px-6">
          {pleaseProvideMoreInfoText[lang]}
        </AlertDialog.Description>
        <div className="grow overflow-auto px-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex max-w-3xl flex-col pb-8">
              <textarea
                placeholder={tellUsYourStoryText[lang]}
                name="previousExpungement"
                rows="8"
                {...register("expunged_before_story", { required: true })}
              ></textarea>
              {errors.expunged_before_story && (
                <p className={errorUnderInput} id="parole-error">
                  {answerIsRequired[lang]}
                </p>
              )}
            </div>
            <div className="flex flex-col justify-center">
              <button
                type="submit"
                className="w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </form>
        </div>
      </div>
    </AlertModal>
  );
}
