import { useLocation, useNavigate } from "react-router-dom";

const ifYouAreNotCitizenText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <>
          Si NO es ciudadano de los Estados Unidos, le recomendamos encarecidamente que hable con un
          abogado de inmigración antes de continuar con la verificación de sus antecedentes.
        </>
      );
    default:
      return (
        <>
          If you are <b>NOT a United States citizen</b>, we strongly recommend that you speak with
          an <b>immigration attorney</b> before proceeding with your record clearance.
        </>
      );
  }
};

const iUnderstandText = { en: "I understand", es: "Entiendo" };

export function UnitedStatesCitizen() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";

  const handleClick = () => {
    navigate("/register/parole", { state: state });
  };

  return (
    <>
      <section id="bodyText">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-10">
          <div>{ifYouAreNotCitizenText(lang)}</div>
        </div>
      </section>
      <section id="button">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <button
            type="button"
            onClick={handleClick}
            className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
          >
            {iUnderstandText[lang]}
          </button>
        </div>
      </section>
    </>
  );
}
