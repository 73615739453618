import RasaLogo from "@images/rasa-logo.svg";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { createPerson } from "../../../../../api/noAuthApi";
import { useEffect, useState } from "react";
import { handleEmailError } from "../../../../../utils/handleError";
import { ConfirmInfoModal } from "./ConfirmInfoModal";
import { daysOfMonthOptions } from "../../../../../constants/daysOfMonth";
import { monthsOptions } from "../../../../../constants/months";
import { yearsOptions } from "../../../../../constants/years";
import {
  container5,
  errorUnderInput,
  inputLabel,
  inputWrapper,
  inputWrapperFlex,
  labelMain,
  primaryButton,
  secondaryButton,
} from "../../../../../constants/classnames";
import { STATE_LIST } from "../../../../../constants/usStates";
import { EmailInUseModal } from "./EmailInUseModal";
import { nextText } from "../../../../../components/buttonBackNext";

const letsGetStarted = { en: "let's get started!", es: "¡Comencemos!" };
const signUpToLearn = (lang, usState) => {
  switch (lang) {
    case "es":
      return (
        <div>
          Regístrate para aprender qué hay en su registro penal de <b>{usState}</b> y si es elegible
          para la eliminación de antecedentes penales.
        </div>
      );
    default:
      return (
        <div>
          Sign up to learn what is on your <b>{usState}</b> record and whether it is eligible for
          record clearance.
        </div>
      );
  }
};
export const firstNameText = { en: "first name", es: "nombre" };
export const firstNameLegal = { en: "legal first name", es: "nombre legal" };
export const middleNameText = { en: "middle name(s)", es: "segundo nombre(s)" };
export const lastNameText = { en: "last name", es: "apellido" };
export const lastNameLegal = { en: "legal last name", es: "apellido legal" };
const birthMonth = { en: "birth month", es: "mes de nacimiento" };
const birthDay = { en: "birth day", es: "día de nacimiento" };
const birthYear = { en: "birth year", es: "año de nacimiento" };
export const emailText = { en: "email", es: "correo electrónico" };
export const passwordText = { en: "password", es: "contraseña" };
export const confirmPasswordText = { en: "confirm password", es: "confirmar contraseña" };
const byCheckingThisBox = {
  en:
    "By checking this box, I agree to receive marketing emails from Rasa Legal about their legal services." +
    " I also understand that I can opt out or unsubscribe at any time.",
  es:
    "Al marcar esta casilla, acepto recibir correos electrónicos de marketing de Rasa Legal sobre sus servicios legales." +
    " También entiendo que puedo optar por no participar o cancelar mi suscripción en cualquier momento.",
};
export const cancelText = { en: "cancel", es: "cancelar" };

export const passwordShouldBe = {
  en: "Password should be at least 8 characters long.",
  es: "La contraseña debe tener al menos 8 caracteres.",
};
export const passwordRequired = {
  en: "Password is required and should be at least 8 characters long.",
  es: "La contraseña es requerido y debe tener al menos 8 caracteres.",
};
export const answerIsRequired = { en: "Answer is required.", es: "Se requiere respuesta." };
export const passwordsDoNotMatchText = {
  en: "Passwords do not match.",
  es: "Las contraseñas no coinciden.",
};

export function BasicInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [emailString, setEmailString] = useState("");
  const [dataForConfirm, setDataForConfirm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [usState, setUsState] = useState("");
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const email = { ...register("email", { required: true, pattern: /^\S+@\S+\.\S+$/ }) };

  useEffect(() => {
    if (!state || !state.us_state) {
      navigate("/register");
    }
    if (state && state.us_state) {
      setUsState(state.us_state);
    }
  }, [state, navigate]);

  useEffect(() => {
    if (emailErrorMessage.includes("already in use")) {
      setOpenEmail(true);
      const email = getValues("email");
      setEmailString(email);
    } else {
      setOpenEmail(false);
    }
  }, [emailErrorMessage, getValues]);

  const handleEmailChange = () => {
    setEmailErrorMessage("");
  };

  const handleNextClick = () => {
    const data = getValues();
    if (!data.birth_year) {
      setGenericErrorMessage("Please select birth year");
    } else if (!data.birth_month) {
      setGenericErrorMessage("Please select birth month");
    } else if (!data.birth_day) {
      setGenericErrorMessage("Please select birth day");
    } else {
      if (!passwordMatchError) {
        setGenericErrorMessage("");
        data.birth_date = data.birth_year + "-" + data.birth_month + "-" + data.birth_day;
        setDataForConfirm(data);
        setOpenConfirm(true);
      }
    }
  };

  const handleMatchPasswordBlur = () => {
    const password = getValues("password");
    if (password !== confirmPassword && confirmPassword !== "") {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
      setGenericErrorMessage("");
    }
  };

  const onSubmitAfterConfirm = () => {
    setIsLoading(true);
    const data = getValues();
    // eslint-disable-next-line no-console
    console.log("data....", data);
    if (!passwordMatchError) {
      const dataToSubmit = {
        first_name: data.first_name.trim(),
        middle_name: data.middle_name.trim(),
        last_name: data.last_name.trim(),
        password: data.password.trim(),
        email: data.email.trim(),
        birth_date: (data.birth_date =
          data.birth_year + "-" + data.birth_month + "-" + data.birth_day),
        us_state: usState,
      };
      if (state?.utm_code) {
        dataToSubmit.utm_code = state.utm_code;
      }
      if (state?.peer_referrer_code) {
        const code = state.peer_referrer_code;
        dataToSubmit.peer_referrer_code = code;
        if (dataToSubmit.utm_code.startsWith("referral-client")) {
          dataToSubmit.utm_code = "referral-client" + "-" + code;
        }
      }
      // eslint-disable-next-line no-console
      console.log("dataToSubmit in basic info", dataToSubmit);
      createPerson(dataToSubmit)
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log("response in bsic info", response);
          navigate("/register/extended-info", { state: { ...response, ...data } });
          setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error in bsic info", error);
          setOpenConfirm(false); // make it so they can see the error
          setIsLoading(false);
          handleEmailError(error, setGenericErrorMessage, setEmailErrorMessage, navigate);
        });
    } else {
      setIsLoading(false);
      setGenericErrorMessage("Passwords do not match");
    }
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className={container5}>
          <h2 className={labelMain}>{letsGetStarted[lang]}</h2>
          {signUpToLearn(lang, STATE_LIST[usState])}
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(handleNextClick)}>
          <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
            {/* First Name */}
            <div className="pb-4">
              <label htmlFor="first_name" className={inputLabel}>
                {firstNameLegal[lang]} *
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  placeholder="Jon"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="first_name-error"
                  {...register("first_name", { required: true })}
                />
              </div>
              {errors.first_name && (
                <div className={errorUnderInput} id="first_name-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="middle_name" className={inputLabel}>
                {middleNameText[lang]}
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  placeholder="Paul"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="middle_name-error"
                  {...register("middle_name")}
                />
              </div>
            </div>
            {/* Last Name */}
            <div className="pb-4">
              <label htmlFor="last_name" className={inputLabel}>
                {lastNameLegal[lang]} *
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  placeholder="Smith"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="last_name-error"
                  {...register("last_name", { required: true })}
                />
              </div>
              {errors.last_name && (
                <div className={errorUnderInput} id="last_name-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>

            <div className="pb-4 pt-8">
              <label htmlFor="birth_month" className={inputLabel}>
                {birthMonth[lang]} *
              </label>
              <div className={inputWrapper}>
                <select
                  id="birth_month"
                  name="birth_month"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                            placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  {...register("birth_month", { required: true })}
                >
                  <option value="" disabled />
                  {monthsOptions(lang).map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              {errors.birth_month && (
                <div className={errorUnderInput} id="last_name-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="birth_day" className={inputLabel}>
                {birthDay[lang]} *
              </label>
              <div className={inputWrapper}>
                <select
                  id="birth_day"
                  name="birth_day"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                            placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  {...register("birth_day", { required: true })}
                >
                  <option value="" disabled />
                  {daysOfMonthOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              {errors.birth_day && (
                <div className={errorUnderInput} id="birth_day-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>
            <div className="pb-12">
              <label htmlFor="birth_year" className={inputLabel}>
                {birthYear[lang]} *
              </label>
              <div className={inputWrapper}>
                <select
                  id="birth_year"
                  name="birth_year"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                            placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  {...register("birth_year", { required: true })}
                >
                  <option value="" disabled />
                  {yearsOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              {errors.birth_year && (
                <div className={errorUnderInput} id="birth_year-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>

            <div className="pb-4">
              <label htmlFor="email" className={inputLabel}>
                {emailText[lang]} *
              </label>
              <div className={inputWrapper}>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  placeholder="you@example.com"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="email-error"
                  {...email}
                  onChange={(e) => {
                    email.onChange(e); // method from hook form register
                    handleEmailChange(e); // your method
                  }}
                />
              </div>
              {errors.email && (
                <div className={errorUnderInput} id="email-error">
                  {answerIsRequired[lang]}
                </div>
              )}
              {emailErrorMessage && (
                <div className={errorUnderInput} id="email-error">
                  {emailErrorMessage}
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="password" className={inputLabel}>
                {passwordText[lang]} *
              </label>
              <div className={inputWrapperFlex}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="-mr-7 block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  // placeholder="Create a password"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="password-error"
                  {...register("password", { required: true, minLength: 8 })}
                  onBlur={handleMatchPasswordBlur}
                />
                {showPassword ? (
                  <EyeSlashIcon
                    className="relative -left-5 top-4 h-7 text-gray-400"
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <EyeIcon
                    className="relative -left-5 top-4 h-7 text-gray-400"
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              <div className="mt-2 text-sm text-gray-400">{passwordShouldBe[lang]}</div>
              {errors.password && (
                <div className={errorUnderInput} id="password-error">
                  {passwordRequired[lang]}
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="confirm-password" className={inputLabel}>
                {confirmPasswordText[lang]} *
              </label>
              <div className={inputWrapperFlex}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirm-password"
                  id="confirm-password"
                  className="-mr-7 block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  // placeholder="Create a password"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="confirm-password-error"
                  onBlur={handleMatchPasswordBlur}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {passwordMatchError && (
                <div className={errorUnderInput} id="confirm-password-error">
                  {passwordsDoNotMatchText[lang]}
                </div>
              )}
            </div>
            <div className="pb-6">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    defaultChecked
                    id="terms"
                    aria-describedby="terms-description"
                    name="terms"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-rasapink focus:ring-rasapink"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <div id="terms-description" className="text-gray-500">
                    {byCheckingThisBox[lang]}
                  </div>
                </div>
              </div>
            </div>
            {/* form submit and cancel buttons */}
            <div className="flex flex-col justify-center">
              {emailErrorMessage && (
                <div className="mt-2 text-center text-sm text-red-600" id="email-error">
                  {emailErrorMessage}
                </div>
              )}
              {genericErrorMessage && (
                <div className="mb-2 text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button type="submit" className={primaryButton}>
                {nextText[lang]}
              </button>
              <Link to="/">
                <button type="button" className={secondaryButton}>
                  {cancelText[lang]}
                </button>
              </Link>
            </div>
          </div>
          <ConfirmInfoModal
            open={openConfirm}
            setOpen={setOpenConfirm}
            onSubmit={onSubmitAfterConfirm}
            data={dataForConfirm}
            isLoading={isLoading}
            lang={lang}
          />
          <EmailInUseModal
            open={openEmail}
            setOpen={setOpenEmail}
            email={emailString}
            lang={lang}
          />
        </form>
      </section>
    </>
  );
}
