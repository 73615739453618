// partnership utm codes
export const scm_utm = "email_scm2025_promo"; // in use until end of april 2025
export const scm_utm_manual = "email_scm2025_manual_promo"; // in use until end of april 2025
export const utmFree = [
  "davis-tech",
  "honestjobs_jul24_az",
  "demo",
  "watson-quickadd",
  "watson-quickadd-partnership",
];
export const REFERRAL_CLIENT = "referral-client";
export const REFERRALCLIENT = "referralclient";

export const PARTNERSHIP_ = "partnership_";

export const _PROMO = "_promo";

export const isApril2025 = () => {
  // second chance month is April, all users get free tool use
  // we can remove this mayFirst logic after april 2025
  const mayFirst = new Date(2025, 4, 1); // month is zero indexed
  const today = new Date();
  return today < mayFirst;
};

export const isFreeUtm = (utm_code) => {
  if (isApril2025()) {
    return true;
  }
  const utmCodeFromStorage = localStorage.getItem("utm_code");
  if (utmCodeFromStorage && utmCodeFromStorage === scm_utm) {
    return true;
  } else {
    return (
      utmFree.includes(utm_code) ||
      (utm_code && utm_code.startsWith(PARTNERSHIP_)) ||
      (utm_code && utm_code.endsWith(_PROMO))
    );
  }
};

export const vocUtm = "partnership_voc";
