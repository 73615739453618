import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ExpungedModal } from "./expungedModal";
import { WhyModal } from "@components";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { updatePerson } from "../../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../../constants/surveyTypes";
import { handleError } from "../../../../../utils/handleError";
import { errorUnderInput, questionMain3 } from "../../../../../constants/classnames";
import { noText, yesText } from "../openCases";
import { nextText } from "../../../../../components/buttonBackNext";
import { answerIsRequired } from "../../personInfo/basicInfo";

const haveYouEverExpungedText = {
  en: "have you ever sealed or expunged your record ",
  es: "¿alguna vez ha sellado o expurgado su registro ",
};
const beforeText = {
  en: "before?",
  es: "antes?",
};
export const ifYouveClearedBeforeText = {
  en: "If you've cleared your record before, this could impact whether you are eligible now.",
  es: "Si has limpiado su registro antes, esto podría afectar si es elegible ahora.",
};

export function Expunged() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";

  const [open, setOpen] = useState(false);
  const [openWhy, setOpenWhy] = useState(false);
  const [localState, setLocalState] = useState(state);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const newState = { ...state, ...data };
    setLocalState(newState);
    if (data.expunged_before === "yes") {
      setOpen(true);
    } else {
      const newState = { ...state, ...data };
      saveAndContinue(newState);
    }
  };

  const modalDone = (data) => {
    const newState = { ...localState, ...data };
    saveAndContinue(newState);
  };

  const saveAndContinue = (currentState) => {
    const currentMandatorySurvey = currentState.surveys.find(
      (survey) => survey.type === MANDATORY_SURVEY,
    );
    // eslint-disable-next-line no-console
    console.log("currentMandatorySurvey", currentMandatorySurvey);
    const stateToSubmit = {
      profile_id: currentState.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: {
            race: currentMandatorySurvey.answers.race,
            race_other: currentMandatorySurvey.answers.race_other || "", // optional field
            ethnicity: currentMandatorySurvey.answers.ethnicity,
            open_cases: currentMandatorySurvey.answers.open_cases,
            owe_fines: currentMandatorySurvey.answers.owe_fines,
            on_probation_or_parole: currentMandatorySurvey.answers.on_probation_or_parole,
            other_convictions: currentMandatorySurvey.answers.other_convictions,
            other_convictions_list: currentMandatorySurvey.answers.other_convictions_list,
            expunged_before: currentState.expunged_before,
            expunged_before_story: currentState.expunged_before_story,
          },
        },
      ],
    };
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in expunged", stateToSubmit);
    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in expunged", response);
        navigate("/register/income", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in expunged", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            {haveYouEverExpungedText[lang]}
            <span className="whitespace-nowrap">
              {beforeText[lang]}
              <InformationCircleIcon
                className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
                onClick={() => setOpenWhy(true)}
              />
            </span>
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-6 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="expungedRadio"
                  id="expunged-yes"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="yes"
                  aria-invalid="false"
                  aria-describedby="expunged-error"
                  {...register("expunged_before", { required: true })}
                />
                <label
                  htmlFor="expunged-yes"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  {yesText[lang]}
                </label>
              </div>
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="expungedRadio"
                  id="expunged-no"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="no"
                  aria-invalid="false"
                  aria-describedby="expunged-error"
                  {...register("expunged_before", { required: true })}
                />
                <label
                  htmlFor="expunged-no"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  {noText[lang]}
                </label>
              </div>
              {errors.expunged_before && (
                <p className={errorUnderInput} id="parole-error">
                  {answerIsRequired[lang]}
                </p>
              )}
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </section>
      <WhyModal
        open={openWhy}
        setOpen={setOpenWhy}
        answer={ifYouveClearedBeforeText[lang]}
        lang={lang}
      />
      <ExpungedModal open={open} setOpen={setOpen} done={modalDone} lang={lang} />
    </>
  );
}
