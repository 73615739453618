import { rasaCallNumber, rasaEmail, rasaTextNumber } from "./ContactUsModal";

const callText = { en: "call", es: "llamar" };
const textText = { en: "text", es: "texto" };
const emailText = { en: "email", es: "correo electrónico" };

export const ContactInfo = ({ hasFreshworks = true, lang = "en" }) => {
  return (
    <div className="ml-10 mt-2">
      <ul className="list-disc">
        <li className="mb-4">
          {callText[lang]}:{" "}
          <a className="mr-4 text-rasapink underline" href={`tel:${rasaCallNumber}`}>
            {rasaCallNumber}
          </a>
        </li>
        {hasFreshworks && (
          <>
            <li className="mb-4">
              {textText[lang]}:{" "}
              <a className="mr-4 text-rasapink underline" href={`sms:${rasaTextNumber}`}>
                {rasaTextNumber}
              </a>
            </li>
          </>
        )}
        <li className="mb-4">
          {emailText[lang]}:{" "}
          <a className="mr-4 text-rasapink underline" href={`mailto:${rasaEmail}`}>
            {rasaEmail}
          </a>
        </li>
      </ul>
    </div>
  );
};
