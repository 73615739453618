import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../../utils/toTitleCase";
import { useEffect, useState } from "react";
import { cleanDispositionString, getSeverityString } from "./utils";
import { primaryButtonSmall } from "../../../constants/classnames";
import { TextUs } from "./MessageBoxes";
import { AutoExpungementNote } from "./AutoExpungementNote";

const greyRow = "flex justify-between mb-4 p-2 bg-gray-100";
const valueDiv = "flex-1 w-44 font-semibold";
const options = { year: "numeric", month: "long", day: "numeric" };

export const caseNumberText = { en: "Case Number", es: "Número de caso" };
export const locationText = { en: "Location", es: "Ubicación" };
const utahPriceDefault = (lang) => {
  switch (lang) {
    case "es":
      return (
        <div>
          Rasa puede ayudarle a borrar sus registros elegibles por <b>¡sólo $500 por caso!</b> Esto
          incluye todas las tarifas gubernamentales excepto la tarifa de solicitud de BCI de $65. Si
          su caso requiere una apelación ante BCI, eso también está incluido en esta tarifa.
        </div>
      );
    default:
      return (
        <div>
          Rasa can help you clear your eligible records for <b>just $500 per case!</b> This includes
          all government fees except the $65 BCI Application Fee. If your case requires an appeal to
          BCI, that is also included in this fee.
        </div>
      );
  }
};

const utahPriceSubsidized = (lang) => {
  switch (lang) {
    case "es":
      return (
        <div>
          según su respuesta sobre ingresos, parece que puede calificar para una exención de tarifas
          del gobierno. Si obtiene una exención, Rasa puede ayudarlo a limpiar sus registros
          elegibles por <b>sólo $250 por caso</b> (no incluye la tarifa de solicitud de BCI de $65).
        </div>
      );
    default:
      return (
        <div>
          Based on your income answer, it looks like you may qualify for a government fee waiver! If
          you obtain a waiver, Rasa can help clear your eligible records for
          <b> just $250 per case</b> (does not include $65 BCI Application Fee).
        </div>
      );
  }
};

export const reasonsText = { en: "Reason(s)", es: "Razón(es)" };
export const translateReasons = (lang, reasons) => {
  if (lang === "en") {
    return reasons;
  }
  return reasons
    .replaceAll("too many", "demasiados")
    .replaceAll("total cases", "casos en total")
    .replaceAll("open case", "caso abierto")
    .replaceAll("Under DOC supervision", "bajo la supervisión del DOC");
};
const mayBeEligibleText = {
  en: "Our preliminary screening indicates that this case may be eligible for expungement",
  es: "Nuestra evaluación preliminar indica que este caso puede ser elegible para la eliminación de antecedentes penales",
};
const mayBeEligibleLaterText = {
  en:
    "Our preliminary screening indicates that this case is not eligible for expungement now, due to you being in a waiting period. " +
    "Expungement eligibility is dynamic and can be impacted by additional criminal activity. " +
    "However, if you obtain no additional charges, this record may become eligible after ",
  es:
    "Nuestra evaluación preliminar indica que este caso no es elegible para la eliminación de antecedentes penales ahora, " +
    "debido a que usted se encuentra en un período de espera. La elegibilidad para la eliminación de antecedentes penales " +
    "es dinámica y puede verse afectada por actividades delictivas adicionales. Sin embargo, si no obtiene cargos adicionales, " +
    "este registro puede volverse elegible después de ",
};
const needMoreInfoText = {
  en: "Our preliminary screening indicates we need more information to determine eligibility for this case",
  es: "Nuestra evaluación preliminar indica que necesitamos más información para determinar la elegibilidad para este caso",
};
const notEligibleText = {
  en: "Our preliminary screening indicates that this case is not eligible for clearance",
  es: "Nuestra evaluación preliminar indica que este caso no es elegible para autorización",
};
const ifYouWouldLikeOurTeamText = {
  en: "If you would like one of our team members to manually determine your eligibility, ",
  es: "Si desea que uno de los miembros de nuestro equipo determine manualmente su elegibilidad, ",
};
const ifYouHaveAQuestionText = {
  en: "If you have a question about these results, ",
  es: "Si tiene alguna pregunta sobre estos resultados, ",
};
const toLearnMoreText = {
  en: "To learn more about Utah's waiting periods, ",
  es: "Para obtener más información sobre los períodos de espera de Utah, ",
};
export const clickHereText = { en: "click here", es: "haga clic aquí" };
export const toHaveSomeoneContactYouText = {
  en: "to have someone from Rasa contact you.",
  es: "para que alguien de Rasa se comunique con usted.",
};
export const violationDescriptionText = {
  en: "Violation Description",
  es: "Descripción de la infracción",
};
export const chargeText = { en: "Charge", es: "Cargo" };
export const severityText = { en: "Severity", es: "Gravedad" };
export const judgmentDateText = { en: "Judgment Date", es: "Fecha del juicio" };
export const typeOfRecordText = { en: "Type of Record", es: "Tipo de registro" };
export const violationCodeText = { en: "Violation Code", es: "Código de violación" };
export const wouldYouLikeToTalkToUsText = {
  en: "Would you like to talk to us about next steps?",
  es: "¿Le gustaría hablar con nosotros sobre los próximos pasos?",
};
export const haveRasaContactYouText = {
  en: "have rasa contact you",
  es: "haga que rasa se comunique con usted.",
};

export function CaseDetailsModalUT({
  open,
  setOpen,
  setOpenMessageUs,
  data,
  receivedAnyMessage,
  autoExpungementEligibleNow,
  hasFreshworks,
  lang = "en",
}) {
  let [caseStatus, setCaseStatus] = useState("");
  let [isClearableNow, setIsClearableNow] = useState(false);
  let [notEligibleNow, setNotEligibleNow] = useState("");
  let [reasons, setReasons] = useState("");
  let [utPriceSentence, setUtPriceSentence] = useState(utahPriceDefault(lang));

  useEffect(() => {
    if (data.isFeeWaiverEligible) {
      setUtPriceSentence(utahPriceSubsidized(lang));
    }

    if (data.expungementEligibleNow) {
      setCaseStatus(mayBeEligibleText[lang]);
      setIsClearableNow(true);
    } else if (data.expungementEligibleLater) {
      setCaseStatus(mayBeEligibleLaterText[lang]);
      setIsClearableNow(false);
      setNotEligibleNow(ifYouHaveAQuestionText[lang]);
    } else if (data.needMoreInfo) {
      setCaseStatus(needMoreInfoText[lang]);
      setIsClearableNow(false);
      setNotEligibleNow(ifYouWouldLikeOurTeamText[lang]);
    } else {
      //  if we get here, everything has been false; not eligible
      setCaseStatus(notEligibleText[lang]);
      setIsClearableNow(false);
      setNotEligibleNow(ifYouHaveAQuestionText[lang]);
      setReasons(data.reasons);
    }
  }, [data, lang]);

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[95vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <AlertDialog.Title className="mb-4 px-6 font-serif text-2xl">
              {data.violation}
            </AlertDialog.Title>
            <div className="-mt-2 ml-6 font-bold">
              {caseNumberText[lang]}: {data.caseNumber}
            </div>
            <div className="mb-6 ml-6 font-bold">
              {locationText[lang]}: {data.court}
            </div>
            <AlertDialog.Description className="mb-6 px-6">
              {caseStatus}
              {!isClearableNow && data.expungementEligibleLater ? (
                <>
                  <b>{data.expungementEligibleDate}.</b>
                  <div className="mt-4">
                    {toLearnMoreText[lang]}
                    <span className="text-rasapink underline">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.rasa-legal.com/utah/utahs-courts-expungement-process/"
                      >
                        {clickHereText[lang]}.
                      </a>
                    </span>
                  </div>
                </>
              ) : (
                <>.</>
              )}
              {reasons && (
                <div>
                  <b>{reasonsText[lang]}:</b> {translateReasons(lang, reasons)}.
                </div>
              )}
              {autoExpungementEligibleNow && <AutoExpungementNote lang={lang} />}
            </AlertDialog.Description>
            {!receivedAnyMessage && (
              <>
                <AlertDialog.Description className="mb-4 px-6">
                  {isClearableNow && (
                    <>
                      <div className="mb-6">{utPriceSentence}</div>
                      <div className="text-center font-semibold">
                        {wouldYouLikeToTalkToUsText[lang]}
                        <button
                          onClick={() => setOpenMessageUs(true)}
                          type="button"
                          className={primaryButtonSmall}
                        >
                          {haveRasaContactYouText[lang]}
                        </button>
                      </div>
                    </>
                  )}
                  {notEligibleNow && (
                    <>
                      {notEligibleNow}
                      <Link onClick={setOpenMessageUs}>
                        <span className="text-rasapink underline">{clickHereText[lang]}</span>
                      </Link>{" "}
                      {toHaveSomeoneContactYouText[lang]}
                    </>
                  )}
                </AlertDialog.Description>
              </>
            )}
            {hasFreshworks && <TextUs lang={lang} />}
            <div>
              <div className="px-6">
                {data.charges.map((charge, index) => {
                  let recordType = "--";
                  if (charge.disposition) {
                    if (charge.disposition.toLowerCase().includes("guilty")) {
                      recordType = "conviction";
                    } else {
                      recordType = cleanDispositionString(charge.disposition);
                    }
                  }
                  return (
                    <div key={`charge-${data.case_number}-${index}`} className="mb-12">
                      <div className="mb-2 mt-6 font-bold">
                        {chargeText[lang]} {index + 1}
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">{violationDescriptionText[lang]}</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.description
                            ? toTitleCase(charge.description.toLowerCase())
                            : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">{severityText[lang]}</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.severity ? getSeverityString(charge.severity) : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">{judgmentDateText[lang]}</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.disposition_date
                            ? new Date(charge.disposition_date).toLocaleDateString("en-US", options)
                            : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">{typeOfRecordText[lang]}</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>{toTitleCase(recordType)}</div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">{violationCodeText[lang]}</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>{charge.code ? charge.code : "--"}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
