const january = { en: "January", es: "Enero" };
const february = { en: "February", es: "Febrero" };
const march = { en: "March", es: "Marzo" };
const april = { en: "April", es: "Abril" };
const may = { en: "May", es: "Mayo" };
const june = { en: "June", es: "Junio" };
const july = { en: "July", es: "Julio" };
const august = { en: "August", es: "Agosto" };
const september = { en: "September", es: "Septiembre" };
const october = { en: "October", es: "Octubre" };
const november = { en: "November", es: "Noviembre" };
const december = { en: "December", es: "Diciembre" };

export const monthsOptions = (lang) => {
  return [
    { value: "01", label: january[lang] || "January" },
    { value: "02", label: february[lang] || "February" },
    { value: "03", label: march[lang] || "March" },
    { value: "04", label: april[lang] || "April" },
    { value: "05", label: may[lang] || "May" },
    { value: "06", label: june[lang] || "June" },
    { value: "07", label: july[lang] || "July" },
    { value: "08", label: august[lang] || "August" },
    { value: "09", label: september[lang] || "September" },
    { value: "10", label: october[lang] || "October" },
    { value: "11", label: november[lang] || "November" },
    { value: "12", label: december[lang] || "December" },
  ];
};
