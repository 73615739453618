const selectAnOption = { en: "-- Select an option --", es: "" };

const billboardText = {
  en: "Billboard",
  es: "Cartel Publicitario",
};
const communityPartnerSpecify = {
  en: "Community Partner (please specify below)",
  es: "Socio comunitario (por favor especifica más abajo)",
};
const employerSpecify = {
  en: "Employer (please specify below)",
  es: "Empleador (por favor especifica más abajo)",
};
const facebook = { en: "Facebook", es: "Facebook" };
const flyer = { en: "Flyer", es: "Volante" };
const googleSearch = { en: "Google Search", es: "Google" };
const instagram = { en: "Instagram", es: "Instagram" };
const newsRadioSpecify = {
  en: "News - Radio (please specify below)",
  es: "Noticias - Radio (por favor especifica más abajo)",
};
const newsTvSpecify = {
  en: "News - TV (please specify below)",
  es: "Noticias - TV (por favor especifica más abajo)",
};
const newsWebsiteSpecify = {
  en: "News - Website (please specify below)",
  es: "Noticias - Sitio web (por favor especifica más abajo)",
};
const nextdoor = { en: "Nextdoor", es: "Nextdoor" };
const rasaEmail = { en: "Rasa Email", es: "Correo electrónico de Rasa" };
const tiktok = { en: "TikTok", es: "TikTok" };
const wordOfMouthSpecify = {
  en: "Word of Mouth / Referral (please specify below)",
  es: "Boca a boca / Referido (por favor especifica más abajo)",
};
const otherSpecify = {
  en: "Other (please specify below)",
  es: "Otro (por favor especifica más abajo)",
};

export const REFERRAL_SOURCE = (lang = "en") => [
  { value: "", text: selectAnOption[lang] },
  { value: "billboard", text: billboardText[lang] },
  { value: "communityPartnerSpecify", text: communityPartnerSpecify[lang] },
  { value: "employerSpecify", text: employerSpecify[lang] },
  { value: "facebook", text: facebook[lang] },
  { value: "flyer", text: flyer[lang] },
  { value: "googleSearch", text: googleSearch[lang] },
  { value: "instagram", text: instagram[lang] },
  { value: "newsRadioSpecify", text: newsRadioSpecify[lang] },
  { value: "newsTvSpecify", text: newsTvSpecify[lang] },
  { value: "newsWebsiteSpecify", text: newsWebsiteSpecify[lang] },
  { value: "nextdoor", text: nextdoor[lang] },
  { value: "rasaEmail", text: rasaEmail[lang] },
  { value: "tiktok", text: tiktok[lang] },
  { value: "wordOfMouthSpecify", text: wordOfMouthSpecify[lang] },
  { value: "otherSpecify", text: otherSpecify[lang] },
];
