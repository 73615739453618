const youMayQualifyText = {
  en: "You may qualify to get help from our community partner, ",
  es: "Puede calificar para recibir ayuda de nuestro socio comunitario, ",
};
const pleaseApplyText = {
  en: "Please apply for their services and have your Voc Rehab case manager contact Rasa.",
  es: "Solicite sus servicios y haga que su administrador de casos de Voc Rehab se comunique con Rasa.",
};
const vocMayPayText = {
  en: "may pay legal and government fees for Rasa to handle your record clearance!",
  es: "podría pagar honorarios legales y gubernamentales para que Rasa se encargue de la autorización de su registro!",
};

export const VocBox = ({ lang = "en" }) => {
  return (
    <div className="mt-1 rounded border border-rasablue bg-rasa-blue-50 p-1 text-center leading-6">
      <div>
        {youMayQualifyText[lang]}
        <span className="text-rasapink underline">
          <a target="_blank" rel="noreferrer" href="https://jobs.utah.gov/usor/vr/index.html">
            Vocational Rehabilitation
          </a>
        </span>
      </div>
      <div className="mt-3">{pleaseApplyText[lang]}</div>
      <div className="mt-3 font-bold">Vocational Rehabilitation {vocMayPayText[lang]}</div>
    </div>
  );
};
