import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import Select from "react-tailwindcss-select";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { createMessage } from "../../../api/secureApi";
import { loading } from "../../../components/loading";
export const textText = { en: "text (SMS)", es: "texto (SMS)" };
export const phoneText = { en: "phone call", es: "llamada telefónica" };
export const emailText = { en: "email", es: "correo electrónico" };
export const noPrefText = { en: "no preference", es: "sin preferencia" };

export const contactOptions = (lang) => [
  { value: "text (SMS)", label: textText[lang] },
  { value: "phone call", label: phoneText[lang] },
  { value: "email", label: emailText[lang] },
  { value: "no preference", label: noPrefText[lang] },
];

const oopsNeedMoreInfoText = {
  en: "Oops! It looks like we'll need a bit more info.",
  es: "¡Ups! Parece que necesitaremos un poco más de información.",
};
const courtDataText = {
  en: "Court data isn't always accurate and sometimes requires a human touch.",
  es: "Los datos judiciales no siempre son precisos y a veces requieren un toque humano.",
};
const letUsTryText = { en: "Let us try again!", es: "¡Intentémoslo de nuevo!" };
const weWillManuallyText = {
  en: "We will manually look up your records",
  es: "Buscaremos manualmente sus registros",
};
const weWillContactText = {
  en: "We will contact you to discuss your eligibility",
  es: "Nos comunicaremos con usted para discutir su elegibilidad",
};
const howContactYouText = {
  en: "How would like us to contact you?",
  es: "¿Cómo le gustaría que nos comuniquemos con usted?",
};
const pleaseTellText = {
  en: "Please tell us about your records (court/county, type of record, when, etc.)",
  es: "Infórmenos sobre sus registros (tribunal/condado, tipo de registro, cuándo, etc.)",
};
const sendMessageText = { en: "send message", es: "enviar mensaje" };

export function NoResultsModal({
  open,
  setOpen,
  profileId,
  setReceivedNoResultsMessage,
  setReceivedAnyMessage,
  lang = "en",
}) {
  const [message, setMessage] = useState("");
  const [preferredContactMethod, setPreferredContactMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleContactChange = (obj) => {
    setPreferredContactMethod(obj);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message !== "" && !!preferredContactMethod) {
      setError("");
      const dataToSubmit = {
        body: message,
        type: "contact_me_no_results",
        contact_media: preferredContactMethod.value,
      };
      setIsLoading(true);
      createMessage(profileId, dataToSubmit)
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log("response", response);
          setReceivedNoResultsMessage(true);
          setReceivedAnyMessage(true);
          setOpen(false);
          setIsLoading(false);
          // toast.success("Your message has been sent to Rasa.");
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error", error);
          // setOpen(false);
          setError(
            "There was an error sending your message." +
              " Please email us at tech-help@rasa-legal.com",
          );
          setIsLoading(false);
        });
    } else {
      if (message === "") {
        setError("Please include a message about your record.");
      } else if (!preferredContactMethod) {
        setError("Please select a preferred method for us to contact you.");
      }
    }
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-800 opacity-90" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[110vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white py-4 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex max-h-[80vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Title className="mt-8 px-6 text-center font-serif text-xl leading-5">
              <div>{oopsNeedMoreInfoText[lang]}</div>
              <div className="mt-2 text-center text-lg leading-5">{courtDataText[lang]}</div>
            </AlertDialog.Title>
            <AlertDialog.Description className="px-6 text-base">
              <section id="form">
                <form>
                  <div className="container mx-auto mb-6 mt-2 flex max-w-3xl flex-col px-2">
                    <div className="my-2">
                      <div>{letUsTryText[lang]}</div>
                      <div className="my-4">
                        <ol className="ml-12 list-decimal">
                          <li>{weWillManuallyText[lang]}</li>
                          <li>{weWillContactText[lang]}</li>
                        </ol>
                      </div>
                      <div>{pleaseTellText[lang]}</div>
                      <div className="flex max-w-3xl flex-col pb-8">
                        <textarea rows="10" onChange={handleMessageChange}></textarea>
                      </div>
                      <div className="mt-2">{howContactYouText[lang]}</div>
                      <div className="relative my-2 rounded-md shadow-sm">
                        <Select
                          id="contactMethods"
                          value={preferredContactMethod}
                          onChange={handleContactChange}
                          options={contactOptions(lang)}
                          isMultiple={false}
                          // isClearable
                          classNames={{
                            menuButton: () =>
                              "flex text-sm px-2 py-2 border border-black rounded shadow-sm transition-all duration-300 focus:outline-none" +
                              " bg-white hover:border-gray-400 focus:border-pink-500 focus:ring focus:ring-pink-500/20 [&_p]:whitespace-break-spaces",
                            listItem: () =>
                              "block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded" +
                              " break-normal text-gray-500 hover:bg-pink-100 hover:text-rasapink",
                            tagItem: () =>
                              "flex text-sm bg-purple p-1 mr-1 border border-gray-200 rounded [&_svg]:w-4",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </section>
            </AlertDialog.Description>
            <div className="mb-10 flex flex-col justify-center">
              {error && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {error}
                </div>
              )}
              <button
                type="button"
                className="mx-8 rounded-full bg-rasapink p-4 font-bold text-white hover:shadow-xl"
                onClick={handleSendMessage}
              >
                {isLoading ? loading : sendMessageText[lang]}
              </button>
            </div>
            <div className="mb-4 h-14"> .</div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
