import { otherText } from "../surveyMandatory/otherConvictions/step2Form";

const facebookText = {
  en: "Facebook groups (please specify below)",
  es: "Grupos de Facebook (por favor especifica)",
};
const jobFairText = { en: "job fair", es: "feria de empleo" };
const otherOnlineJobText = {
  en: "other online job board (please specify below)",
  es: "otro tablero de trabajos en línea (por favor especifica)",
};
const wordOfMouthText = { en: "word of mouth", es: "boca a boca" };

export const jobOpportunitiesOptions = (lang) => [
  {
    value: "Facebook groups (please specify below)",
    label: facebookText[lang],
  },
  { value: "Indeed", label: "Indeed" },
  { value: "job fair", label: jobFairText[lang] },
  { value: "LinkedIn", label: "LinkedIn" },
  {
    value: "other online job board (please specify below)",
    label: otherOnlineJobText[lang],
  },
  { value: "word of mouth", label: wordOfMouthText[lang] },
  { value: "Zip Recruiter", label: "Zip Recruiter" },
  { value: "other (please specify below)", label: otherText[lang] },
];

const businessText = { en: "Business", es: "Negocios" };
const constructionText = { en: "Construction", es: "Construcción" };
const educationText = { en: "Education", es: "Educación" };
const foodAndBeverageText = { en: "Food and Beverage", es: "Alimentación" };
const healthText = { en: "Health", es: "Salud" };
const hospitalityText = { en: "Hospitality", es: "Hospitalidad" };
const manufacturingText = { en: "Manufacturing", es: "Manufactura" };
const professionalServicesText = { en: "Professional Services", es: "Profesional" };
const retailText = { en: "Retail", es: "Minorista" };
const technologyText = { en: "Technology", es: "Tecnología" };
const transportationText = { en: "Transportation", es: "Transporte" };

export const industryOptions = (lang) => [
  {
    value: "Business",
    label: businessText[lang],
  },
  { value: "Construction", label: constructionText[lang] },
  { value: "Education", label: educationText[lang] },
  {
    value: "Food and Beverage",
    label: foodAndBeverageText[lang],
  },
  { value: "Health", label: healthText[lang] },
  { value: "Hospitality", label: hospitalityText[lang] },
  { value: "Manufacturing", label: manufacturingText[lang] },
  { value: "Professional Services", label: professionalServicesText[lang] },
  { value: "Retail", label: retailText[lang] },
  {
    value: "Technology",
    label: technologyText[lang],
  },
  { value: "Transportation", label: transportationText[lang] },
  { value: "Other (please specify below)", label: otherText[lang] },
];

const someHighSchoolText = { en: "some high school", es: "algo de secundaria" };
const highSchoolDiplomaText = { en: "high school diploma", es: "diploma de secundaria" };
const someCollegeText = { en: "some college", es: "algo de universidad" };
const associatesDegreeText = { en: "associate's degree", es: "título de asociado" };
const bachelorsDegreeText = { en: "bachelor's degree", es: "título de licenciatura" };
const graduateDegreeText = { en: "graduate degree", es: "título de posgrado" };

export const educationLevelOptions = (lang) => [
  { value: "some high school", label: someHighSchoolText[lang] },
  { value: "high school diploma", label: highSchoolDiplomaText[lang] },
  { value: "GED", label: "GED" },
  { value: "some college", label: someCollegeText[lang] },
  { value: "associate's degree", label: associatesDegreeText[lang] },
  { value: "bachelor's degree", label: bachelorsDegreeText[lang] },
  { value: "graduate degree", label: graduateDegreeText[lang] },
];

export const whyRecordClearingOptions = [
  { value: "to get a promotion", label: "to get a promotion" },
  { value: "to get a new job", label: "to get a new job" },
  {
    value: "to improve ability to rent a house or apartment",
    label: "to improve ability to rent a house or apartment",
  },
  {
    value: "to be able to volunteer at my children's school",
    label: "to be able to volunteer at my children's school",
  },
  { value: "to get a college education", label: "to get a college education" },
  { value: "to get a professional license", label: "to get a professional license" },
  { value: "to travel freely", label: "to travel freely" },
  {
    value: "to increase self-confidence",
    label: "to increase self-confidence",
  },
  {
    value: "to eliminate the stigma associated with a record",
    label: "to eliminate the stigma associated with a record",
  },
  {
    value: "to restore my gun rights",
    label: "to restore my gun rights",
  },
  {
    value: "to obtain life insurance",
    label: "to obtain life insurance",
  },
  {
    value: "for religious reasons",
    label: "for religious reasons",
  },
  { value: "other (please specify below)", label: "other (please specify below)" },
];

export const cannedMessageOptionsUt = [
  {
    value: "I would like to hire Rasa for expungement(s)",
    label: "I would like to hire Rasa for expungement(s)",
  },
  {
    value: "I would like to hire Rasa for reduction(s)",
    label: "I would like to hire Rasa for reduction(s)",
  },
  {
    value:
      "I'm not ready to hire, but I would like to talk to Rasa about my record clearance options",
    label:
      "I'm not ready to hire, but I would like to talk to Rasa about my record clearance options",
  },
  {
    value: "Something on this record looks wrong, and I would like to talk to Rasa about it.",
    label: "Something on this record looks wrong, and I would like to talk to Rasa about it.",
  },
];

export const cannedMessageOptionsAz = [
  {
    value: "I would like to hire Rasa for record clearance",
    label: "I would like to hire Rasa for record clearance",
  },
  {
    value:
      "I'm not ready to hire, but I would like to talk to Rasa about my record clearance options",
    label:
      "I'm not ready to hire, but I would like to talk to Rasa about my record clearance options",
  },
  {
    value: "Something on this record looks wrong, and I would like to talk to Rasa about it",
    label: "Something on this record looks wrong, and I would like to talk to Rasa about it",
  },
];

export const getNumberOptions = (num = 12) => {
  const options = [];
  for (let i = 1; i <= num; i++) {
    options.push({ value: i.toString(), label: i.toString() });
  }
  return options;
};
