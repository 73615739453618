import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { loading } from "../../../../components/loading";
import { useState } from "react";
import { primaryButton, secondaryButton } from "../../../../constants/classnames";
import { cancelText } from "../personInfo/basicInfo";

const couponCodeText = { en: "coupon code", es: "código de cupón" };
const yourPriceIsText = (paymentAmount, lang) => {
  switch (lang) {
    case "es":
      return `Su precio es ${paymentAmount}`;
    default:
      return `Your price is ${paymentAmount}`;
  }
};
const applyCodeText = { en: "apply code", es: "aplique el código" };
export function CouponModal({
  open,
  setOpen,
  applyCodeClick,
  continueToPayment,
  isLoading,
  isLoadingContinue,
  couponErrorMessage,
  setCouponErrorMessage,
  successMessage,
  paymentAmount,
  lang,
}) {
  const [code, setCode] = useState(false);

  const handleApply = () => {
    applyCodeClick(code);
  };

  const handleContinue = () => {
    continueToPayment();
  };

  const closeModal = () => {
    setCouponErrorMessage("");
    setOpen(false);
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[75vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => closeModal()}
              />
            </div>
            <AlertDialog.Title className="mt-8 px-10 font-serif text-xl">
              {couponCodeText[lang]}
            </AlertDialog.Title>
            <AlertDialog.Description className="mb-8 px-6 text-base">
              <section id="form">
                <form>
                  <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                    <div className="my-6 flex flex-col justify-center">
                      {couponErrorMessage && (
                        <div className="my-2  text-center text-sm text-red-600" id="email-error">
                          {couponErrorMessage}
                        </div>
                      )}
                      {successMessage ? (
                        <>
                          <div
                            className="my-6 text-center font-bold text-green-600"
                            id="email-error"
                          >
                            <div>{successMessage}</div>
                            <div>{yourPriceIsText(paymentAmount, lang)}</div>
                          </div>
                          <button onClick={handleContinue} type="button" className={primaryButton}>
                            {isLoadingContinue ? loading : "continue"}
                          </button>
                        </>
                      ) : (
                        <>
                          <button onClick={handleApply} type="button" className={primaryButton}>
                            {isLoading ? loading : applyCodeText[lang]}
                          </button>
                          <button
                            onClick={() => closeModal()}
                            type="button"
                            className={secondaryButton}
                          >
                            {cancelText[lang]}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </section>
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
