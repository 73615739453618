import * as AlertDialog from "@radix-ui/react-alert-dialog";
import {
  primaryButtonSmall2,
  secondaryButtonSmall,
  tertiaryButtonSmall,
} from "../../../../../constants/classnames";
import { LoginForgotButtons } from "./loginForgotButtons";

const isText = { en: "Is", es: "" };
const yourEmailText = { en: "your email?", es: "¿Es su correo electrónico?" };
const noNotMe = { en: "no, that's not me", es: "no, no soy yo" };

export function EmailInUseModal({ open, setOpen, email, lang }) {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[75vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[80vh] flex-col">
            <AlertDialog.Title className="my-6 px-10 text-center font-serif text-xl">
              <div className="mb-2">{isText[lang]}</div>
              <div className="mb-2">{email ? <u>{email}</u> : "this"}</div>
              <div>{yourEmailText[lang]}</div>
            </AlertDialog.Title>
            <div className="mb-6 px-6 text-lg">
              <div className="container mx-auto mb-16 mt-2 flex max-w-3xl flex-col px-4">
                <div className="flex flex-col justify-center">
                  <LoginForgotButtons
                    email={email}
                    loginClass={primaryButtonSmall2}
                    forgotClass={tertiaryButtonSmall}
                    lang={lang}
                  />
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className={secondaryButtonSmall}
                  >
                    {noNotMe[lang]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
