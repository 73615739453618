import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { OtherConvictionsModal } from "./otherConvictionsModal";
import { WhyModal } from "@components";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { updatePerson } from "../../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../../constants/surveyTypes";
import { handleError } from "../../../../../utils/handleError";
import { STATE_LIST } from "../../../../../constants/usStates";
import { errorUnderInput, questionMain3 } from "../../../../../constants/classnames";
import { answerIsRequired } from "../../personInfo/basicInfo";
import { nextText } from "../../../../../components/buttonBackNext";
import { noText, yesText } from "../openCases";

const haveYouEverBeenConvicted = {
  en: "have you ever been convicted of a crime in another state, or in federal court?",
  es: "¿Alguna vez has sido condenado por un delito en otro estado o en un tribunal federal?",
};

const ourToolCanOnlyGive = (usState, lang) => {
  switch (lang) {
    case "es":
      return (
        <div>
          Nuestra herramienta solo puede brindarle información sobre sus registros en
          <b>{usState}</b>. Los casos fuera del estado o federales no aparecerán aquí y podrían
          afectar su elegibilidad.
        </div>
      );
    default:
      return (
        <>
          Our tool can only give you information about your records in <b>{usState}</b>.
          Out-of-state or federal cases will not appear here and could impact your eligibility.
        </>
      );
  }
};

export function OtherConvictions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const lang = localStorage.getItem("language") || "en";
  const [open, setOpen] = useState(false);
  const [openWhy, setOpenWhy] = useState(false);
  const [localState, setLocalState] = useState(state);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const newState = { ...state, ...data };
    setLocalState(newState);
    if (data.other_convictions === "yes") {
      setOpen(true);
    } else {
      saveAndContinue(newState);
    }
  };

  const modalDone = (data) => {
    saveAndContinue({ other_convictions_list: [...data.convictions], ...localState });
  };

  const saveAndContinue = (currentState) => {
    const currentMandatorySurvey = currentState.surveys.find(
      (survey) => survey.type === MANDATORY_SURVEY,
    );
    // eslint-disable-next-line no-console
    console.log("currentMandatorySurvey", currentMandatorySurvey);
    const stateToSubmit = {
      profile_id: currentState.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: {
            race: currentMandatorySurvey.answers.race,
            race_other: currentMandatorySurvey.answers.race_other || "", // optional field
            ethnicity: currentMandatorySurvey.answers.ethnicity,
            open_cases: currentMandatorySurvey.answers.open_cases,
            owe_fines: currentMandatorySurvey.answers.owe_fines,
            on_probation_or_parole: currentMandatorySurvey.answers.on_probation_or_parole,
            other_convictions: currentState.other_convictions,
            other_convictions_list: currentState.other_convictions_list,
          },
        },
      ],
    };
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in open conv", stateToSubmit);
    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in other conv", response);
        navigate("/register/expunged", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in other conv", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            {haveYouEverBeenConvicted[lang]}
            <InformationCircleIcon
              className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
              onClick={() => setOpenWhy(true)}
            />
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-6 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="otherConvictionsRadio"
                  id="otherConvictions-yes"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="yes"
                  aria-invalid="false"
                  aria-describedby="open-cases-error"
                  {...register("other_convictions", { required: true })}
                />
                <label
                  htmlFor="otherConvictions-yes"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  {yesText[lang]}
                </label>
              </div>
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="otherConvictionsRadio"
                  id="otherConvictions-no"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="no"
                  aria-invalid="false"
                  aria-describedby="open-cases-error"
                  {...register("other_convictions", { required: true })}
                />
                <label
                  htmlFor="otherConvictions-no"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  {noText[lang]}
                </label>
              </div>
              {errors.other_convictions && (
                <div className={errorUnderInput} id="parole-error">
                  {answerIsRequired[lang]}
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                {nextText[lang]}
              </button>
            </div>
          </div>
        </form>
      </section>
      <WhyModal
        open={openWhy}
        setOpen={setOpenWhy}
        answer={ourToolCanOnlyGive(STATE_LIST[localState.us_state], lang)}
        lang={lang}
      />
      <OtherConvictionsModal
        open={open}
        setOpen={setOpen}
        done={modalDone}
        usState={STATE_LIST[localState.us_state]}
      />
    </>
  );
}
