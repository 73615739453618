import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ContactInfo } from "./ContactInfo";

export const rasaCallNumber = "866-521-0178";
export const rasaTextNumber = "877-748-9889";
export const rasaEmail = "info@rasa-legal.com";

const weWereUnableText = {
  en: "We were unable to confirm your identity. Please get in touch with us to move forward.",
  es: "No pudimos confirmar su identidad. Por favor póngase en contacto con nosotros para seguir adelante.",
};

export function ContactUsModal({
  openContactUs,
  setOpenContactUs,
  hasFreshworks,
  verifyFailed,
  lang = "en",
}) {
  return (
    <AlertDialog.Root open={openContactUs}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[50vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpenContactUs(false)}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Description className="mb-8 px-6 text-lg">
              <div className="my-4 text-center">
                {verifyFailed && <span>{weWereUnableText[lang]}</span>}
              </div>
              <ContactInfo hasFreshworks={hasFreshworks} lang={lang} />
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
