import { messageStatus, primaryButtonSmall } from "../../../constants/classnames";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { PATIENT_MESSAGE } from "./results";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { AZ, STATE_LIST, UT } from "../../../constants/usStates";
import { rasaTextNumber } from "./ContactUsModal";
import { useEffect, useState } from "react";
import { CleanSlateBox } from "./CleanSlateBox";
import { PriceBox } from "./PriceBox";
import { VocBox } from "./VocBox";
import { showMenu } from "../login/login";
import { preliminaryScreening } from "../register/introInfo";
import { reasonsText, translateReasons } from "./CaseDetailsModalUT";

const welcomeText = { en: "Welcome", es: "Bienvenido" };
const youMayHaveText = {
  en: "You may have records to clear!",
  es: "¡Es posible que tenga registros que borrar!",
};
const haveUsContactText = { en: "have us contact you", es: "haz que te contactemos" };
const youCanTextText = { en: "you can text us at ", es: "puedes enviarnos un mensaje de texto a " };
const orText = { en: "or", es: "o" };
const rasaIsPoweredText = {
  en: "Rasa is powered by court data, which is not perfect. If you notice a case is missing, or you find an error, ",
  es: "Rasa se basa en datos judiciales, que no son perfectos. Si nota que falta un caso o encuentra un error, ",
};
const pleaseMessageUsText = {
  en: "please message us here.",
  es: "por favor envíenos un mensaje aquí.",
};
const ourTextText = { en: "Our text number is: ", es: "Nuestro número de texto es: " };
const weWillContactYouText = { en: "We will contact you soon.", es: "Lo contactaremos pronto." };
const thankYouText = { en: "Thank you for your message!", es: "¡Gracias por tu mensaje!" };
const thankYouForReachingOutText = {
  en: "Thank you for reaching out!",
  es: "¡Gracias por contactarnos!",
};
const weFoundText = { en: "We found ", es: "Encontramos " };
const criminalRecordText = { en: " criminal record", es: " antecedente penal" };
const criminalRecordsText = { en: " criminal records", es: " antecedentes penales" };
const trafficRecordText = { en: " traffic record", es: "registro de tráfico" };
const trafficRecordsText = { en: " traffic records", es: " registros de tráfico" };
const inText = { en: "in", es: "en" };
const thatMatchTheNameText = { en: "that match the name ", es: "que coincida con el nombre " };
const andBirthdateText = { en: "and birth date", es: "y fecha de nacimiento" };
const youProvidedText = { en: " you provided.", es: " usted proporcionó." };
const youCanTextUsDirectlyText = {
  en: "You can text us directly!",
  es: "¡Puede enviarnos un mensaje de texto directamente!",
};
const notEligibleText = { en: "Not eligible", es: "No elegible" };
const inSomeCasesText = (lang, receivedAnyMessage, setOpenMessageUs) => {
  switch (lang) {
    case "es":
      return (
        <div className="my-2">
          En algunos casos, una persona que no es elegible para la eliminación de antecedentes
          penales puede volverse elegible al buscar y obtener con éxito una{" "}
          <span className="text-rasapink underline">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.rasa-legal.com/utah/utahs-offence-reduction-process/"
            >
              Reducción 402.
            </a>
          </span>{" "}
          <span className="font-semibold">
            Rasa puede ayudarle a buscar una reducción 402 por $500 por caso.
          </span>{" "}
          {!receivedAnyMessage && (
            <>
              Para discutir esta posibilidad con uno de nuestros expertos, por favor{" "}
              <Link onClick={() => setOpenMessageUs(true)}>
                <span className="text-rasapink underline">
                  haga clic aquí para enviarnos un mensaje.
                </span>
              </Link>
            </>
          )}
        </div>
      );
    default:
      return (
        <div className="my-2">
          In some cases, a person who is not eligible for expungement{" "}
          <b>
            <i>may</i>
          </b>{" "}
          become eligible by seeking and successfully obtaining a{" "}
          <span className="text-rasapink underline">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.rasa-legal.com/utah/utahs-offence-reduction-process/"
            >
              402 Reduction.
            </a>
          </span>{" "}
          <span className="font-semibold">
            Rasa can help you seek a 402 Reduction for $500 per case.
          </span>{" "}
          {!receivedAnyMessage && (
            <>
              To discuss this possibility with with one of our experts, please{" "}
              <Link onClick={() => setOpenMessageUs(true)}>
                <span className="text-rasapink underline">click here to message us.</span>
              </Link>
            </>
          )}
        </div>
      );
  }
};
const ifYouHaveACaseTypeText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <div className="my-2">
          Si tiene un tipo de caso que no es elegible para la eliminación de antecedentes penales o
          tiene demasiados casos para ser elegibles para la eliminación de antecedentes penales, es
          posible que pueda obtener un perdón. Para obtener información sobre el proceso de indulto
          de Utah,{" "}
          <span className="text-rasapink underline">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.rasa-legal.com/utah/utah-pardon-information/"
            >
              haga clic aquí
            </a>
          </span>
        </div>
      );
    default:
      return (
        <div className="my-2">
          If you have a case type that is not eligible for expungement or you have too many cases to
          be eligible for expungement, you might be able to obtain a pardon. For information about
          Utah's pardon process,{" "}
          <span className="text-rasapink underline">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.rasa-legal.com/utah/utah-pardon-information/"
            >
              click here.
            </a>
          </span>
        </div>
      );
  }
};

const openCasesText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <div>
          Respondió que actualmente tiene casos abiertos. Esto probablemente significa que usted{" "}
          <b>no es elegible</b> para borrar sus registros en este momento.
        </div>
      );
    default:
      return (
        <div>
          You answered that you currently have open cases. This probably means you are{" "}
          <b>not eligible</b> to clear your records right now.
        </div>
      );
  }
};
const onProbationText = (lang) => {
  switch (lang) {
    case "es":
      return (
        <div>
          Usted respondió que actualmente se encuentra en libertad condicional o bajo libertad
          condicional. Esto probablemente significa que usted <b>no es elegible</b> para borrar sus
          registros en este momento.
        </div>
      );
    default:
      return (
        <div>
          You answered that you are currently on probation or parole. This probably means you are{" "}
          <b>not eligible</b> to clear your records right now.
        </div>
      );
  }
};

export const ContactBox = ({ setOpenMessageUs, hasFreshworks, lang }) => {
  return (
    <div className="m-4 mx-auto inline-block rounded-md border border-rasapink px-6 py-2 text-center">
      <h3 className="mb-2 font-serif text-xl">{youMayHaveText[lang]}</h3>
      <div>
        <button onClick={() => setOpenMessageUs(true)} type="button" className={primaryButtonSmall}>
          {haveUsContactText[lang]}
        </button>
      </div>
      {hasFreshworks && (
        <>
          <div className="-mt-2">{orText[lang]}</div>
          <div className="mt-2">
            {youCanTextText[lang]}
            <a className="mr-4 text-rasapink underline" href={`sms:${rasaTextNumber}`}>
              {rasaTextNumber}.
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export const ErrorBox = ({ handleErrorMessageUsClick, lang }) => {
  return (
    <div className="my-6">
      {rasaIsPoweredText[lang]}
      <Link onClick={() => handleErrorMessageUsClick(true)}>
        <span className="text-rasapink underline">{pleaseMessageUsText[lang]}</span>
      </Link>
    </div>
  );
};

export const TextUs = ({ lang }) => {
  return (
    <div className="my-2 text-center">
      <div>{youCanTextUsDirectlyText[lang]}</div>
      {ourTextText[lang]}
      <a className="mr-4 text-rasapink underline" href={`sms:${rasaTextNumber}`}>
        {rasaTextNumber}.
      </a>
    </div>
  );
};

export const WelcomeBox = ({
  birthDate,
  clientStatus,
  handleErrorMessageUsClick,
  hasFreshworks,
  isCleanSlateClient,
  isPotentialVocClient,
  isFeeWaiverEligible,
  ineligibleReasons,
  numOfRecords,
  criminalCount,
  trafficCount,
  openCases,
  onProbationOrParole,
  person,
  receivedContactRequest,
  receivedMessage,
  receivedAnyMessage,
  receivedNoResultsMessage,
  recordOrRecords,
  showConsultBox,
  setOpenMessageUs,
  usState,
  lang,
}) => {
  const [aliases, setAliases] = useState("");
  const [aliasCount, setAliasCount] = useState(0);
  const [showErrorBox, setShowErrorBox] = useState(
    !receivedMessage &&
      !receivedNoResultsMessage &&
      !receivedContactRequest &&
      !showMenu(clientStatus),
  );

  useEffect(() => {
    setShowErrorBox(
      !receivedMessage &&
        !receivedNoResultsMessage &&
        !receivedContactRequest &&
        !showMenu(clientStatus),
    );
  }, [receivedMessage, receivedNoResultsMessage, receivedContactRequest, clientStatus]);

  useEffect(() => {
    const count = person?.aliases?.length;
    setAliasCount(count);
    if (count > 0) {
      let str = "";
      person.aliases.map((alias) => {
        str += `${alias.first_name} ${alias.last_name}, `;
      });
      setAliases(str);
    }
  }, [person.aliases]);

  return (
    <section>
      <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
        <h2 className="my-2 font-serif text-2xl">
          {welcomeText[lang]}
          {person.first_name ? ", " + person.first_name : ""}
        </h2>
        {isCleanSlateClient && !showMenu(clientStatus) && (
          <CleanSlateBox isFeeWaiverEligible={isFeeWaiverEligible} lang={lang} />
        )}
        {isPotentialVocClient && !showMenu(clientStatus) && <VocBox lang={lang} />}
        <div>
          {receivedContactRequest && (
            <div className={messageStatus}>
              <div>T{thankYouForReachingOutText[lang]}</div>
              <div>{weWillContactYouText[lang]}</div>
              <div>{PATIENT_MESSAGE}</div>
            </div>
          )}
          {receivedMessage && !receivedNoResultsMessage && !showMenu(clientStatus) && (
            <div className={messageStatus}>
              <div>{thankYouText[lang]}</div>
              <div>{weWillContactYouText[lang]}</div>
              <div>{PATIENT_MESSAGE}</div>
            </div>
          )}
          {receivedNoResultsMessage ? (
            <div className={messageStatus}>
              <div>{thankYouText[lang]}</div>
              <div>We are doing a manual search for your records and will contact you soon.</div>
              <div>{PATIENT_MESSAGE}</div>
            </div>
          ) : (
            <div>
              <div className="my-4 text-lg">
                {weFoundText[lang]}
                {usState === UT ? (
                  <>
                    <span>
                      {criminalCount}
                      {criminalCount === 1 ? criminalRecordText[lang] : criminalRecordsText[lang]}
                    </span>{" "}
                    and{" "}
                    <span>
                      {trafficCount}
                      {trafficCount === 1 ? trafficRecordText[lang] : trafficRecordsText[lang]}
                    </span>{" "}
                    {inText[lang]} {STATE_LIST[usState]}
                  </>
                ) : (
                  <b>
                    {numOfRecords} {STATE_LIST[usState]} {recordOrRecords}
                  </b>
                )}{" "}
                {thatMatchTheNameText[lang]}
                <b>
                  {person.first_name} {person.last_name}
                </b>
                {aliases !== "" && (
                  <>
                    , {aliasCount === 1 ? "alias" : "aliases"} <b>{aliases}</b>
                  </>
                )}{" "}
                {andBirthdateText[lang]}
                <b> {birthDate}</b>
                {youProvidedText[lang]}
              </div>
            </div>
          )}
          <div className="my-3 mt-2 text-center font-bold leading-4 text-gray-600">
            {preliminaryScreening(lang)}
          </div>
        </div>
        {showConsultBox && !showMenu(clientStatus) && (
          <>
            <ContactBox
              setOpenMessageUs={setOpenMessageUs}
              hasFreshworks={hasFreshworks}
              lang={lang}
            />
            {!isCleanSlateClient && (
              <PriceBox usState={usState} isFeeWaiverEligible={isFeeWaiverEligible} lang={lang} />
            )}
          </>
        )}
        {!showConsultBox && usState === AZ && !showMenu(clientStatus) && (
          <PriceBox usState={usState} isFeeWaiverEligible={isFeeWaiverEligible} lang={lang} />
        )}
        {openCases && (
          <div className="relative my-2 rounded-md bg-amber-100 px-6 py-4">
            <div className="absolute -top-3 flex h-6 w-6 items-center justify-center rounded-full bg-amber-100">
              <ExclamationTriangleIcon className="h-4 text-amber-400" />
            </div>
            {openCasesText(lang)}
          </div>
        )}
        {onProbationOrParole && (
          <div className="relative my-2 rounded-md bg-amber-100 px-6 py-4">
            <div className="absolute -top-3 flex h-6 w-6 items-center justify-center rounded-full bg-amber-100">
              <ExclamationTriangleIcon className="h-4 text-amber-400" />
            </div>
            {onProbationText(lang)}
          </div>
        )}
        {usState === UT && ineligibleReasons && (
          <>
            <div className="my-4">
              <XCircleIcon className="float-left mr-1 h-6 text-red-600" />
              {notEligibleText[lang]}
            </div>
            <div className="my-2">
              <span className="font-bold">{reasonsText[lang]}:</span>{" "}
              {translateReasons(lang, ineligibleReasons)}
            </div>
            {inSomeCasesText(lang, receivedAnyMessage, setOpenMessageUs)}
            {/*<div className="my-2">*/}
            {/*  In some cases, a person who is not eligible for expungement{" "}*/}
            {/*  <b>*/}
            {/*    <i>may</i>*/}
            {/*  </b>{" "}*/}
            {/*  become eligible by seeking and successfully obtaining a{" "}*/}
            {/*  <span className="text-rasapink underline">*/}
            {/*    <a*/}
            {/*      target="_blank"*/}
            {/*      rel="noreferrer"*/}
            {/*      href="https://www.rasa-legal.com/utah/utahs-offence-reduction-process/"*/}
            {/*    >*/}
            {/*      402 Reduction.*/}
            {/*    </a>*/}
            {/*  </span>{" "}*/}
            {/*  <span className="font-semibold">*/}
            {/*    Rasa can help you seek a 402 Reduction for $500 per case.*/}
            {/*  </span>{" "}*/}
            {/*  {!receivedAnyMessage && (*/}
            {/*    <>*/}
            {/*      To discuss this possibility with with one of our experts, please{" "}*/}
            {/*      <Link onClick={() => setOpenMessageUs(true)}>*/}
            {/*        <span className="text-rasapink underline">click here to message us.</span>*/}
            {/*      </Link>*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</div>*/}
            {ifYouHaveACaseTypeText(lang)}
            {/*<div className="my-2">*/}
            {/*  If you have a case type that is not eligible for expungement or you have too many*/}
            {/*  cases to be eligible for expungement, you might be able to obtain a pardon. For*/}
            {/*  information about Utah's pardon process,{" "}*/}
            {/*  <span className="text-rasapink underline">*/}
            {/*    <a*/}
            {/*      target="_blank"*/}
            {/*      rel="noreferrer"*/}
            {/*      href="https://www.rasa-legal.com/utah/utah-pardon-information/"*/}
            {/*    >*/}
            {/*      click here.*/}
            {/*    </a>*/}
            {/*  </span>*/}
            {/*</div>*/}
          </>
        )}
        {showErrorBox && <ErrorBox handleErrorMessageUsClick={handleErrorMessageUsClick} />}
        {hasFreshworks && <TextUs lang={lang} />}
      </div>
    </section>
  );
};
