import {
  complete,
  draftingFiling,
  waitingForCourt,
  yourPersonalInfo,
} from "../Descriptions/DescriptionsExpungement";
import { UNKNOWN } from "../../results/results";

// DENIED
// FILED
// GRANTED
// OBJECTION_RECEIVED
// PROPOSED_MATTER
// READY_TO_DRAFT
// READY_TO_FILE
// REPLY_FILED
// SENT_TO_CLIENT_FOR_REVIEW
// WAITING_FOR_PS
// WAITING_FOR_RIGHTS_RESTORATION

// "dead" /motionDenied
export const DENIED = "DENIED";
export const denied = "denied";

// yourPersonalInfo
const PROPOSED_MATTER = "PROPOSED_MATTER";
const WAITING_FOR_PS = "WAITING_FOR_PS";

// draftingFiling,
const WAITING_FOR_RIGHTS_RESTORATION = "WAITING_FOR_RIGHTS_RESTORATION";
const READY_TO_DRAFT = "READY_TO_DRAFT";
const READY_TO_FILE = "READY_TO_FILE";
const SENT_TO_CLIENT_FOR_REVIEW = "SENT_TO_CLIENT_FOR_REVIEW";

//   waitingForCourt,
const FILED = "FILED";
const OBJECTION_RECEIVED = "OBJECTION_RECEIVED";
const REPLY_FILED = "REPLY_FILED";

//   motionComplete,
const GRANTED = "GRANTED";

export const mapWorkflowToStatus = (m, showPsNeeded, setShowPsNeeded) => {
  const workflow_step_name = m.workflow_step_name?.trim().toUpperCase();
  if (
    workflow_step_name === undefined ||
    workflow_step_name === null ||
    workflow_step_name === "" ||
    workflow_step_name?.includes(PROPOSED_MATTER)
  ) {
    m.status = UNKNOWN;
  } else if (workflow_step_name === DENIED) {
    m.status = denied;
  } else if (workflow_step_name?.includes(WAITING_FOR_PS) || showPsNeeded) {
    setShowPsNeeded(true);
    m.status = yourPersonalInfo;
  } else if (
    workflow_step_name.includes(WAITING_FOR_RIGHTS_RESTORATION) ||
    workflow_step_name.includes(READY_TO_DRAFT) ||
    workflow_step_name.includes(READY_TO_FILE) ||
    workflow_step_name.includes(SENT_TO_CLIENT_FOR_REVIEW)
  ) {
    m.status = draftingFiling;
  } else if (
    workflow_step_name.includes(FILED) ||
    workflow_step_name.includes(OBJECTION_RECEIVED) ||
    workflow_step_name.includes(REPLY_FILED)
  ) {
    m.status = waitingForCourt;
  } else if (workflow_step_name?.includes(GRANTED)) {
    m.status = complete;
  } else {
    m.status = draftingFiling;
  }
  return m;
};
